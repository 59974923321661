import * as THREE from "three";
import { sphereMaterial } from "./sphereMaterial";

const DefaultSphereShieldConfig = () => {
  return {
    name: "",
    map: { value: "circle", info: "texture" },
    position: {
      value: { x: 0, y: 0, z: 0 },
      info: {
        x: { min: -20, max: 20 },
        y: { min: -20, max: 20 },
        z: { min: -20, max: 20 },
      },
    },
    scale: {
      value: { x: 1, y: 1, z: 1 },
      info: {
        x: { min: 0, max: 20 },
        y: { min: 0, max: 20 },
        z: { min: 0, max: 20 },
      },
    },
    rotation: {
      value: { x: 0, y: 0, z: 0 },
      info: {
        x: { min: -Math.PI, max: Math.PI },
        y: { min: -Math.PI, max: Math.PI },
        z: { min: -Math.PI, max: Math.PI },
      },
    },
  };
};

function rotationTransform(rotation) {
  return { _x: rotation.x, _y: rotation.y, _z: rotation.z, _order: "XYZ" };
}

export class SphereShield {
  constructor(name, data = null, config = DefaultSphereShieldConfig()) {
    this.data = data;
    this.config = config;
    this.name = name;
    this.config.name = name;

    this.sphere = new THREE.Mesh(
      new THREE.SphereGeometry(1.0, 64, 64),
      sphereMaterial(),
    );
    this.sphere.scale.set(6, 6, 6);
    this.sphere.renderOrder = 101;
    this.sphere.position.y = -1.3;
    this.active = false;
    this.periodic = false;
    this.up = 1;

    this.time = 0;
    this.sphere.material.uniforms.time.value = 0;
    this.sphere.scale.set(
      this.config.scale.value.x,
      this.config.scale.value.y,
      this.config.scale.value.z,
    );
    this.sphere.rotation.copy(rotationTransform(this.config.rotation.value));
    this.sphere.position.copy(this.config.position.value);
  }

  setData(data) {
    this.data = data;
    if (data) this.setConfigParameters();
  }

  addGUI(node) {
    const subnode = node.addFolder({ title: this.name });
    Object.keys(this.config).forEach((key) => {
      if (key === "name") return;
      const entry = this.config[key];
      if (entry.info != "texture" && entry.info != "model") {
        subnode
          .addInput(entry, "value", { ...entry.info, label: key })
          .on("change", () => {
            this.setConfigParameters();
          });
      } else
        subnode
          .addInput(entry, "value", {
            label: key,
            options: Object.keys(this.data.textures).reduce(
              (options, current) => {
                const o = {};
                o[current] = current;
                return { ...options, ...o };
              },
              {},
            ),
          })
          .on("change", () => {
            this.setConfigParameters();
          });
    });
  }

  setConfigParameters() {
    const mapName = this.config.map.value;
    this.sphere.material.uniforms.map.value = this.data.textures[mapName];
    this.sphere.scale.set(
      this.config.scale.value.x,
      this.config.scale.value.y,
      this.config.scale.value.z,
    );
    this.sphere.rotation.copy(rotationTransform(this.config.rotation.value));
    this.sphere.position.copy(this.config.position.value);
  }

  addMappingValue(key, valueName) {
    this.mapping[key] = this.data[valueName];
  }
  setMap(map) {
    this.sphere.material.uniforms.map.value = map;
  }

  addToScene(object) {
    object.add(this.sphere);
  }
  activate() {
    this.active = true;
    this.sphere.visible = true;

    // this.time = 0;
    this.sphere.material.uniforms.time.value = this.time;
  }

  runUp() {
    this.activate();
    this.up = 1;
  }

  runDown() {
    this.activate();
    this.up = -1;
  }

  deactivate() {
    this.active = false;
    // this.sphere.visible = false;
    // this.time = 1;
    // this.sphere.material.uniforms.time.value = this.time;
  }
  togglePeriodicity() {
    this.periodic = !this.periodic;
  }
  getType() {
    return "SphereShield";
  }
  update() {
    if (!this.active) return;
    this.time += this.up * 0.02; //0.02;
    // this.sphere.material.uniforms.time.value = this.time;
    if (this.time >= 1) {
      // this.deactivate();
      this.time = 1;
    }
    if (this.time <= 0) {
      // this.deactivate();
      this.time = 0;
    }
    this.sphere.material.uniforms.time.value = this.time;
  }
}

// const particleSystem = new DriftParticleSystem(1000, scene);
