import * as THREE from "three";
import vertex from "./ribbonMaterial.glslv";
import fragment from "./ribbonMaterial.glslf";

export const ribbonMaterial = (length, width, position, map) => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    blending: THREE.NormalBlending,
    depthWrite: false,
    depthTest: true,
    side: THREE.DoubleSide,
    uniforms: {
      time: { value: 0 },
      length: { value: length },
      width: { value: width },
      ribbonPosition: { value: new THREE.Vector3().copy(position) },
      map: { value: map },
      // health: { value: 1 },
    },
  });
};
