import * as THREE from "three";
import vertex from "./health.glslv";
import fragment from "./health.glslf";

const healthMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    blending: THREE.NormalBlending,
    uniforms: {
      health: { value: 1 },
    },
  });
};

export default healthMaterial;
