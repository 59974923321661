import * as THREE from "three";
import vertex from "./point.glslv";
import fragment from "./point.glslf";

const pointMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    depthTest: true,
    depthWrite: false,
    alphaTest: 0.0,
    transparent: true,
    blending: THREE.NormalBlending,
    uniforms: {
      sprite: { value: null },
    },
  });
};

export default pointMaterial;
