import { Pane } from "tweakpane";
import * as THREE from "three";
import { getState } from "./state";
import { loadMatCap, assets } from "./loader";
import { setBaseVehicleMatCap, setEnemyVehicleMatCap } from "./vehicle";
import { togglePause, addEnemies } from "./module";
import { updateMaterials } from "./materials";

const state = getState();
let pane;
let globalContext;

// maxSpeed: 120,
// forwardForce: 2500,
// backwardForce: 0,
// mass: 1800,
// collisionDamage: 0.02,
// bilateralCoefficientMin: 0.03,
// bilateralCoefficientMax: 0.5,
// angularCoefficient: 20,
// driftSpeed: 22,

const matCapInputsArray = [
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
  document.createElement("input"),
];

const matcapOptions = {
  "vehicle-base": "vehicle-base",
  //orangeMatCap: "orangeMatCap",
  "vehicle-enemy": "vehicle-enemy",
  //bluishMatCap: "bluishMatCap",
  black: "black",
  ground: "ground",
  "misc-1": "misc-1",
  "misc-2": "misc-2",
  "misc-3": "misc-3",
  //violetYellowMatCap: "violetYellowMatCap",
  customMatCap0: "customMatCap0",
  customMatCap1: "customMatCap1",
  customMatCap2: "customMatCap2",
  customMatCap3: "customMatCap3",
  customMatCap4: "customMatCap4",
  customMatCap5: "customMatCap5",
  customMatCap6: "customMatCap6",
  customMatCap7: "customMatCap7",
  customMatCap8: "customMatCap8",
  none: "none",
};

function baseCarChange() {
  const v = globalContext.getVehicle(0);

  const options = new Module.VehicleOptions();
  Object.keys(state.physics.baseCar).forEach((key) => {
    options[key] = state.physics.baseCar[key];
  });

  v.setOptions(options);
}

function enemyCarChange() {
  for (let i = 1; i < 11; i++) {
    const v = globalContext.getVehicle(i);

    const options = new Module.VehicleOptions();
    Object.keys(state.physics.enemyCar).forEach((key) => {
      options[key] = state.physics.enemyCar[key];
    });

    v.setOptions(options);
  }
}

export function buildGUI(inGlobalContext, functions) {
  globalContext = inGlobalContext;
	
pane = new Pane();
  const physics = pane.addFolder({ title: "Physics", expanded: false });
  const base = physics.addFolder({ title: "BaseCar", expanded: false });
  base
    .addInput(state.physics.baseCar, "maxSpeed", { min: 30, max: 1000 })
    .on("change", baseCarChange);
  base
    .addInput(state.physics.baseCar, "forwardForce", { min: 500, max: 10000 })
    .on("change", baseCarChange);
  base
    .addInput(state.physics.baseCar, "mass", { min: 200, max: 10000 })
    .on("change", baseCarChange);
  base
    .addInput(state.physics.baseCar, "collisionDamage", { min: 0.0, max: 0.1 })
    .on("change", baseCarChange);
  base
    .addInput(state.physics.baseCar, "bilateralCoefficientMin", {
      min: 0.0,
      max: 0.1,
    })
    .on("change", baseCarChange);
  base
    .addInput(state.physics.baseCar, "bilateralCoefficientMax", {
      min: 0.1,
      max: 1,
    })
    .on("change", baseCarChange);
  base
    .addInput(state.physics.baseCar, "driftSpeed", { min: 0, max: 200.0 })
    .on("change", baseCarChange);

  const enemy = physics.addFolder({ title: "EnemyCar", expanded: false });
  enemy
    .addInput(state.physics.enemyCar, "maxSpeed", { min: 30, max: 1000 })
    .on("change", enemyCarChange);
  enemy
    .addInput(state.physics.enemyCar, "forwardForce", { min: 500, max: 10000 })
    .on("change", enemyCarChange);
  enemy
    .addInput(state.physics.enemyCar, "mass", { min: 200, max: 10000 })
    .on("change", enemyCarChange);
  enemy
    .addInput(state.physics.enemyCar, "collisionDamage", { min: 0.0, max: 0.1 })
    .on("change", enemyCarChange);
  enemy
    .addInput(state.physics.enemyCar, "bilateralCoefficientMin", {
      min: 0.0,
      max: 0.1,
    })
    .on("change", enemyCarChange);
  enemy
    .addInput(state.physics.enemyCar, "bilateralCoefficientMax", {
      min: 0.1,
      max: 1,
    })
    .on("change", enemyCarChange);
  enemy
    .addInput(state.physics.enemyCar, "driftSpeed", { min: 0, max: 200.0 })
    .on("change", enemyCarChange);

  const matcaps = pane.addFolder({ title: "Matcaps", expanded: false });

  Object.keys(state.matcaps).forEach((key) => {
    matcaps
      .addInput(state.matcaps, key, { options: matcapOptions })
      .on("change", () => {
        setBaseVehicleMatCap(assets.matcaps[state.matcaps.baseVehicle]);
        setEnemyVehicleMatCap(assets.matcaps[state.matcaps.enemyVehicle]);
        updateMaterials();
      });
  });

  const matCapLoaders = pane.addFolder({
    title: "MatCapLoaders",
    expanded: false,
  });

  for (let i = 0; i < 9; i++) {
    const loadMatCapButton = matCapLoaders
      .addButton({
        title: "LoadMatCap" + i,
      })
      .on("click", () => {
        matCapInputsArray[i].type = "file";
        matCapInputsArray[i].click();
        const inputCallback = () => {
          if (
            matCapInputsArray[i].files &&
            matCapInputsArray[i].files.length > 0
          ) {
            loadMatCap(
              URL.createObjectURL(matCapInputsArray[i].files[0]),
              i,
              () => {
                setBaseVehicleMatCap(assets.matcaps[state.matcaps.baseVehicle]);

                setEnemyVehicleMatCap(
                  assets.matcaps[state.matcaps.enemyVehicle],
                );
                updateMaterials();
                matCapInputsArray[i] = document.createElement("input");
              },
            );
          } else requestAnimationFrame(inputCallback);
        };

        requestAnimationFrame(inputCallback);
      });
  }

  pane.addButton({ title: "Pause" }).on("click", togglePause);
  pane.addButton({ title: "Restart" }).on("click", functions.restart);
  pane.addButton({ title: "AddEnemies" }).on("click", addEnemies);
}
