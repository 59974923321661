import * as THREE from "three";
import vertex from "./ribbonMaterial.glslv";
import fragment from "./ribbonMaterial.glslf";

export const ribbonMaterial = (curveData0, curveData1, map) => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    blending: THREE.NormalBlending,
    depthWrite: false,
    depthTest: true,
    side: THREE.DoubleSide,
    uniforms: {
	    s: { value: 0 },
      time: { value: 0 },
      slope0: { value: curveData0.slope },
      radius0: { value: curveData0.radius },
      circlePosition0: { value: curveData0.position },
      slope1: { value: curveData1.slope },
      radius1: { value: curveData1.radius },
      circlePosition1: { value: curveData1.position },
      map: { value: map },
      // health: { value: 1 },
    },
  });
};
