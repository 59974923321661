import * as THREE from "three";
import healthMaterial from "./material/healthMaterial";
import armorMaterial from "./material/armorMaterial";
import {
  setDeath,
  getDeath,
  togglePause,
  upArmor,
  downArmor,
  isStartScreen,
} from "./module";
import { playSound, playSoundNoCheck, setVolume, setRate } from "./sound";

const vehicles = [];
const vehicleDistanceVector = new THREE.Vector3();
let velocityParameter = 0;

function getVehicles() {
  return vehicles;
}
function lengthOfXZ(vector) {
  return Math.pow(vector.x * vector.x + vector.z * vector.z, 0.5);
}

function setBaseVehicleMatCap(matcap) {
  const baseVehicle = vehicles[0];
  baseVehicle.chassis.traverse((o) => {
    if (o.isMesh) o.material.matcap = matcap;
  });
}

function setEnemyVehicleMatCap(matcap) {
  for (let i = 1; i < vehicles.length; i++) {
    const enemyVehicle = vehicles[i];
    enemyVehicle.chassis.traverse((o) => {
      if (o.isMesh) o.material.matcap = matcap;
    });
  }
}

function createVehicle(
  wheelPositions,
  wheelRadius,
  chassisSize,
  position,
  mass,
  chassisMaterial,
  wheelMaterial,
  vehicles,
  globalContext,
) {
  const wheelPrototype = new THREE.Mesh(
    new THREE.CylinderGeometry(
      2 * wheelRadius,
      2 * wheelRadius,
      2 * 0.2,
      24,
      1,
    ),
    wheelMaterial,
  );

  wheelPrototype.geometry.applyQuaternion(
    new THREE.Quaternion().setFromAxisAngle(
      new THREE.Vector3(0, 0, 1),
      Math.PI / 2,
    ),
  );

  const wheels = [];
  const physicalWheels = new Module.Wheels();
  wheelPositions.forEach((wheelPosition, index) => {
    physicalWheels.addWheel(
      new Module.btVector3(wheelPosition.x, wheelPosition.y, wheelPosition.z),
    );

    const wheel = wheelPrototype.clone();
    wheel.position.copy(wheelPositions[index]);

    wheels.push({
      object: wheel,
      index: index,
      transform: new Module.btTransform(),
    });
  });
  physicalWheels.setRestLength(0.6);

  const box = new THREE.Mesh(
    new THREE.BoxGeometry(
      2 * chassisSize.x,
      2 * chassisSize.y,
      2 * chassisSize.z,
    ),
    chassisMaterial,
  );

  box.position.set(position.x, position.y, position.z);

  const index = globalContext.createVehicle(
    physicalWheels,
    wheelRadius,
    new Module.btVector3(chassisSize.x, chassisSize.y, chassisSize.z),
    new Module.btVector3(position.x, position.y, position.z),
    mass,
  );
  const s = 0.3;
  const healthBar = new THREE.Mesh(
    new THREE.BoxGeometry(s, s, s),
    healthMaterial(),
  );
  healthBar.material.uniforms.health.value = 1.0;

  healthBar.scale.x = 10.0;
  healthBar.position.y = 5.0;

  // box.add(healthBar);

  const armorBar = new THREE.Mesh(
    new THREE.BoxGeometry(s, s, s),
    armorMaterial(),
  );
  armorBar.material.uniforms.armor.value = 0.0;

  armorBar.scale.x = 10.0;
  armorBar.position.y = 3.0;

  // box.add(armorBar);

  vehicles.push({
    chassis: box,
    wheels: wheels,
    index: index,
    transform: new Module.btTransform(),
    healthBar: healthBar,
    armorBar: armorBar,
    time: 0.0,
    alive: true,
  });
}

function createVehicleWithModel(
  modelData,
  wheelPositions,
  wheelRadius,
  chassisSize,
  position,
  mass,
  chassisMaterial,
  wheelMaterial,
  vehicles,
  globalContext,
) {
  chassisMaterial.transparent = true;
  wheelMaterial.transparent = true;
  const wheels = [];
  const restLength = 0.01; //0.01; //0.03;
  const physicalWheels = new Module.Wheels();
  wheelPositions.forEach((wheelPosition, index) => {
    physicalWheels.addWheel(
      new Module.btVector3(
        wheelPosition.x * 1.15,
        wheelPosition.y * 1.27 - 0.0,
        wheelPosition.z * 1.25,
      ),
    );
    physicalWheels.setRestLength(restLength);

    const wheel = modelData.wheels[index].clone(); //wheelPrototype.clone();
    wheel.material = wheelMaterial;
    wheel.position.copy(wheelPositions[index]);

    wheels.push({
      object: wheel,
      index: index,
      transform: new Module.btTransform(),
    });
  });

  let box = modelData.mesh.clone();
  box.material = chassisMaterial;
  //box.scale.set(4, 4, 4);
  //box.position.set(position.x, position.y, position.z);
  box.geometry.computeBoundingBox();

  const size = box.geometry.boundingBox.getSize(new THREE.Vector3());
  size.y *= 0.5;
  size.z *= 0.9;
  size.x *= 0.85;

  let box2 = new THREE.Mesh(
    new THREE.BoxGeometry(size.x, size.y, size.z),
    new THREE.MeshBasicMaterial({ color: "red" }),
  );

  const index = globalContext.createVehicle(
    physicalWheels,
    wheelRadius / 1.0,
    new Module.btVector3(size.x / 2.0, size.y / 2.0, size.z / 2.0),
    new Module.btVector3(position.x, position.y, position.z),
    mass,
  );
  const s = 0.3;
  const healthBar = new THREE.Mesh(
    new THREE.BoxGeometry(s, s, s),
    healthMaterial(),
  );
  healthBar.material.uniforms.health.value = 1.0;

  healthBar.scale.x = 10.0;
  healthBar.position.y = 8.0;

  // box.add(healthBar);

  const armorBar = new THREE.Mesh(
    new THREE.BoxGeometry(s, s, s),
    armorMaterial(),
  );
  armorBar.material.uniforms.armor.value = 0.0;
  armorBar.scale.x = 10.0;
  armorBar.position.y = 7.0;

  // box.add(armorBar);

  box.scale.set(1, 1, 1);

  const chassis = new THREE.Object3D();
  chassis.add(box);
  //chassis.add(box2);
  box.position.y = -0.1;
  box.position.z = 0.1;

  vehicles.push({
    chassis: chassis, //chassis,
    wheels: wheels,
    index: index,
    transform: new Module.btTransform(),
    healthBar: healthBar,
    armorBar: armorBar,
    time: 0.0,
    driftTime: performance.now(),
    engineTime: performance.now(),
    alive: true,
    targetScale: 1.0,
    soundIndex: -1,
    velocityParameter: 0,
    zeroVelocityCounter: 0,
  });
  playSound("Engine" + index);
}
function setVehicleScale(vehicleObject, scale) {
  const chassis = vehicleObject.chassis;
  const wheels = vehicleObject.wheels;

  chassis.scale.set(scale, scale, scale);
  chassis.opacity = scale;
  wheels.forEach((w) => {
    w.object.scale.set(scale, scale, scale);
    w.object.opacity = scale;
  });
}

function spawnVehicle(vehicleObject, globalContext) {
  const vehicle = globalContext.getVehicle(vehicleObject.index);
  const baseVehiclePosition = vehicles[0].chassis.position;
  // if (toActivate) vehicle.activate();
  vehicle.activate();

  const direction = [Math.random() - 0.5, Math.random() - 0.5];
  direction[0] /= Math.sqrt(
    direction[0] * direction[0] + direction[1] * direction[1],
  );
  direction[1] /= Math.sqrt(
    direction[0] * direction[0] + direction[1] * direction[1],
  );
  const spawnPosition = new Module.btVector3(
    baseVehiclePosition.x + 200 * direction[0] + Math.random() * 100,
    0.5,
    baseVehiclePosition.z + 200 * direction[1] + Math.random() * 100,
  );
  vehicle.setPosition(spawnPosition);
  vehicle.setHealth(1.0);

  vehicleObject.chassis.position.set(
    spawnPosition.x(),
    spawnPosition.y(),
    spawnPosition.z(),
  );

  //timeout should prevent flickering in a death place when respawn
  setTimeout(() => {
    vehicleObject.targetScale = 1.0;
  }, 100);
  vehicleObject.alive = true;
}

function updateVehicle(
  vehicleObject,
  counter,
  direction,
  btDirection,
  particleSystem,
  deathCallback,
  globalContext,
) {
  if (vehicleObject.index !== 0) {
    //scale threshold instead of alive again to prevent flickering
    vehicleObject.healthBar.visible =
      vehicleObject.chassis.scale.x > 0.8 && !isStartScreen();
    vehicleObject.armorBar.visible =
      vehicleObject.chassis.scale.x > 0.8 && !isStartScreen();
  } else {
    vehicleObject.healthBar.visible = !getDeath();
    vehicleObject.armorBar.visible = !getDeath();
  }

  if (isStartScreen() && vehicleObject.index !== 0) {
    return;
  }

  const vehicle = globalContext.getVehicle(vehicleObject.index);

  const up = vehicle.getUpVector();
  if (vehicleObject.index === 0) {
    // console.log(vehicleObject.targetScale);
    if (vehicleObject.chassis.scale.x < 0.01 && up.y() < 0.2) {
      vehicle.setPosition(
        new Module.btVector3(
          vehicleObject.chassis.position.x,
          0.2,
          vehicleObject.chassis.position.z,
        ),
      );
      vehicleObject.targetScale = 1.0;
    } else if (up.y() < 0.2) {
      vehicleObject.targetScale = 0.0;
    }
    // else {
    //      vehicleObject.targetScale = 1.0;
    //    }
    // console.log(up.x(), up.y(), up.z());
  }

  const scaleVelocity = vehicleObject.index == 0 && up.y() < 0.2 ? 0.15 : 0.15;
  // const scaleVelocity = 0.15;

  setVehicleScale(
    vehicleObject,
    vehicleObject.chassis.scale.x +
      scaleVelocity *
        (vehicleObject.targetScale - vehicleObject.chassis.scale.x),
  );

  const now = performance.now();
  let delta = now - vehicleObject.time;
  let timeCondition = delta > 1000.0;
  if (!vehicleObject.alive && timeCondition) {
    spawnVehicle(vehicleObject, globalContext);
    return;
  }

  const transform = vehicleObject.transform;
  vehicle.getChassisTransform(transform);

  const quat = transform.getRotation();
  const position = transform.getOrigin();

  vehicleObject.chassis.quaternion.set(quat.x(), quat.y(), quat.z(), quat.w());
  vehicleObject.chassis.position.set(
    position.x(),
    position.y() + 0.2,
    position.z(),
  );
  const wheels = vehicleObject.wheels;
  for (let i = 0; i < 4; i++) {
    const transform = wheels[i].transform;
    vehicle.getWheelTransform(wheels[i].index, transform);
    const quat = transform.getRotation();
    const position = transform.getOrigin();
    wheels[i].object.quaternion.set(quat.x(), quat.y(), quat.z(), quat.w());
    wheels[i].object.position.set(position.x(), position.y(), position.z());
  }

  let dot = vehicle.getDotBetweenLinearVelocityAndForwardAxis();
  vehicle.getLinearVelocity(btDirection);
  direction.set(btDirection.x(), btDirection.y(), btDirection.z());

  const velocity = lengthOfXZ(direction);
  if (velocity < 0.1 && vehicleObject.alive) {
    vehicleObject.zeroVelocityCounter++;
  } else vehicleObject.zeroVelocityCounter = 0;

  // console.log(vehicleObject.index, " ", velocity);

  if (velocity < 0.1) dot = 1.0;
  direction.normalize();

  if (
    (vehicleObject.alive && vehicleObject.index != 0) ||
    (vehicleObject.index == 0 && !getDeath())
  ) {
    switch (counter % 2) {
      case 0:
        if (Math.abs(dot) < 0.9) {
          particleSystem.emit(wheels[2].object.position, direction, dot);
          particleSystem.emit(wheels[2].object.position, direction, dot);
          // particleSystem.emit(wheels[2].object.position, direction, dot);
          // particleSystem.emit(wheels[2].object.position, direction, dot);
        }
        break;
      case 1:
        if (Math.abs(dot) < 0.9) {
          particleSystem.emit(wheels[3].object.position, direction, dot);
          particleSystem.emit(wheels[3].object.position, direction, dot);
          // particleSystem.emit(wheels[3].object.position, direction, dot);
          // particleSystem.emit(wheels[3].object.position, direction, dot);
        }
        break;
      case 2:
      default:
        break;
    }
  }
  const health = vehicle.getHealth();
  vehicleObject.healthBar.material.uniforms.health.value = health;
  vehicleObject.healthBar.position.copy(vehicleObject.chassis.position);
  vehicleObject.healthBar.position.y = 8.0;
  vehicleObject.healthBar.rotation.y = Math.PI / 2.0;

  const armor = vehicle.getArmor();
  vehicleObject.armorBar.material.uniforms.armor.value = armor;
  vehicleObject.armorBar.position.copy(vehicleObject.chassis.position);
  vehicleObject.armorBar.position.y = 7.0;
  vehicleObject.armorBar.rotation.y = Math.PI / 2.0;

  if (vehicleObject.index == 0 && armor > 0.0) {
    upArmor();
  } else if (vehicleObject.index == 0 && armor <= 0.0) {
    downArmor();
  }

  const baseVehiclePosition = vehicles[0].chassis.position;

  vehicleDistanceVector.copy(vehicleObject.chassis.position);
  vehicleDistanceVector.sub(baseVehiclePosition);
  const length = vehicleDistanceVector.length();

  const hit = vehicle.getHit();

  if (hit && length < 30) {
    const volume = 0.2 * (1 / (0.2 * length + 1));
    playSound("HitHurt", volume);
  }

  const nowDrift = performance.now();
  const deltaDrift = nowDrift - vehicleObject.driftTime;
  if (Math.abs(dot) < 0.9 && deltaDrift > 25 && length < 70) {
    const index = Math.min(Math.floor(Math.random() * 3 + 1), 3);

    const volume =
      vehicleObject.index === 0 ? 0.01 : 0.01 * (1 / (0.1 * length + 1));

    vehicleObject.soundIndex = playSoundNoCheck(
      "Drift" + index,
      volume,
      vehicleObject.soundIndex,
    );
    vehicleObject.driftTime = nowDrift;
  }

  const nowEngine = performance.now();
  const deltaEngine = nowEngine - vehicleObject.engineTime;

  const velocityParameterTarget = Math.max(
    Math.min((Math.max(velocity, 20) - 10) / (35.0 - 10), 1.0),
    0.005,
  );

  vehicleObject.velocityParameter +=
    0.05 * (velocityParameterTarget - vehicleObject.velocityParameter);
  const rateParameter = 0.4 + vehicleObject.velocityParameter * 1.0;

  let volume = 0.05 * vehicleObject.velocityParameter;

  if (vehicleObject.index !== 0) volume *= 1.0 / (0.05 * length + 1);

  setVolume("Engine" + vehicleObject.index, volume);
  setRate("Engine" + vehicleObject.index, rateParameter);

  //console.log(length);
  let deathCondition =
    health <= 0.0 || length > 900.0 || vehicleObject.zeroVelocityCounter > 100;

  if (deathCondition && vehicleObject.alive && vehicleObject.index !== 0) {
    vehicleObject.zeroVelocityCounter = 0;
    vehicleObject.alive = false;
    vehicleObject.time = performance.now();
    //vehicle.setPosition(new Module.btVector3(0.0, -50.0, 0.0));
    vehicleObject.targetScale = 0.0;
    vehicle.deactivate();
  }

  if (health <= 0.0 && vehicleObject.index === 0) {
    vehicleObject.targetScale = 0.0;
    //setDeath(true);
  }
  if (
    vehicleObject.index === 0 &&
    vehicleObject.chassis.scale.x < 0.0001 &&
    !getDeath()
  ) {
    vehicle.deactivate();
    setDeath(true);
    if (deathCallback) deathCallback();
  }
}

export {
  createVehicle,
  createVehicleWithModel,
  updateVehicle,
  spawnVehicle,
  getVehicles,
  setBaseVehicleMatCap,
  setEnemyVehicleMatCap,
};
