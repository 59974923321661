import * as THREE from "three";
import { sphereMaterial } from "./sphereMaterial";

export class ArmorVFX {
  constructor(map, object) {
    this.sphere = new THREE.Mesh(
      new THREE.SphereGeometry(1.0, 64, 64),
      sphereMaterial(),
    );
    this.sphere.scale.set(6, 6, 6);
    this.sphere.renderOrder = 101;
    this.sphere.position.y = -1.3;
    this.active = false;
    this.periodic = false;
    this.up = 1;

    this.time = 0;
    this.sphere.material.uniforms.time.value = 0;
  }

  setMap(map) {
    this.sphere.material.uniforms.map.value = map;
  }

  addToScene(object) {
    object.add(this.sphere);
  }
  activate() {
    this.active = true;
    this.sphere.visible = true;

    // this.time = 0;
    this.sphere.material.uniforms.time.value = this.time;
  }

  runUp() {
    this.activate();
    this.up = 1;
  }

  runDown() {
    this.activate();
    this.up = -1;
  }

  deactivate() {
    this.active = false;
    // this.sphere.visible = false;
    // this.time = 1;
    // this.sphere.material.uniforms.time.value = this.time;
  }
  togglePeriodicity() {
    this.periodic = !this.periodic;
  }
  update() {
    // if (!this.active) return;
    this.time += this.up * 0.02; //0.02;
    // this.sphere.material.uniforms.time.value = this.time;
    if (this.time >= 1) {
      // this.deactivate();
      this.time = 1;
    }
    if (this.time <= 0) {
      // this.deactivate();
      this.time = 0;
    }
    this.sphere.material.uniforms.time.value = this.time;
  }
}

// const particleSystem = new DriftParticleSystem(1000, scene);
