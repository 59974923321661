import * as THREE from "three";
import { playSound } from "./sound";
import { moveFromMovingToCell, moveFromCellToMoving } from "./gridSystem";
import { getVehicles } from "./vehicle";
import {
  isStartScreen,
  downArmor,
  getDeath,
  runHeal,
  stopHeal,
  runNitro,
  stopNitro,
  upArmor,
} from "./module";
let globalContext;

const distanceVector = new THREE.Vector3();

const data = {
  score: 0.0,
};

const types = {
  Road_Aware_01: { boundingBoxMultiplier: new THREE.Vector3(1, 1.0, 1) },
  Road_Aware_02: { boundingBoxMultiplier: new THREE.Vector3(1, 1, 1) },
  Road_Blocker_01: { boundingBoxMultiplier: new THREE.Vector3(1, 1, 1) },
  Road_Blocker_02: { boundingBoxMultiplier: new THREE.Vector3(1, 1, 1) },
  Trash_Can_01: { boundingBoxMultiplier: new THREE.Vector3(0.8, 1, 0.8) },
  Trash_Can_02: { boundingBoxMultiplier: new THREE.Vector3(0.9, 1, 0.9) },
  Barrel_01: { boundingBoxMultiplier: new THREE.Vector3(0.9, 1, 0.9) },
  Barrel_03: { boundingBoxMultiplier: new THREE.Vector3(0.9, 1, 0.9) },
  Dump_01: { boundingBoxMultiplier: new THREE.Vector3(0.9, 0.95, 0.9) },
  Dump_02: { boundingBoxMultiplier: new THREE.Vector3(0.9, 0.95, 0.9) },
  Dump_03: { boundingBoxMultiplier: new THREE.Vector3(0.8, 0.9, 0.8) },
  Dump_04: { boundingBoxMultiplier: new THREE.Vector3(0.8, 0.8, 0.8) },
};
const collectablesReward = {
  Trash_01: 5,
  Trash_02: 10,
  Trash_03: 15,
  Trash_04: 20,
  Trash_05: 25,
  Trash_06: 30,
};

const callbackGenerators = {
  trash: (reward) => {
    return () => {
      data.score += !isStartScreen() ? reward : 0.0;
      playSound("collectTrash");
    };
  },
};

const collectablesCallback = {
  Trash_01: callbackGenerators["trash"](5),
  Trash_02: callbackGenerators["trash"](10),
  Trash_03: callbackGenerators["trash"](15),
  Trash_04: callbackGenerators["trash"](20),
  Trash_05: callbackGenerators["trash"](25),
  Trash_06: callbackGenerators["trash"](30),
  Heal: () => {
    // const vehicle = getVehicles()[0];
    const baseVehicle = globalContext.getVehicle(0);
    const health = baseVehicle.getHealth();
    // baseVehicle.setHealth(health + 0.2);
    baseVehicle.setHealth(health + 0.5);
    runHeal();
    setTimeout(stopHeal, 1000);
  },
  Nitro: () => {
    const baseVehicle = globalContext.getVehicle(0);
    if (!isStartScreen()) baseVehicle.nitroOn();
    runNitro();
    setTimeout(() => {
      baseVehicle.nitroOff();
      stopNitro();
      // stopNitro();
    }, 1000);
    // setTimeout(() => {
    //   stopNitro();
    // }, 1000);
  },
  Armor: () => {
    const baseVehicle = globalContext.getVehicle(0);
    baseVehicle.increaseArmor(1.0);
    // baseVehicle.increaseArmor(0.2);
    // upArmor();
    // setTimeout(() => {
    //   // baseVehicle.nitroOff();
    //   downArmor();
    // }, 2000);
  },
};

function injectGlobalContext(globalContextIn) {
  globalContext = globalContextIn;
}

function createBoxObject(
  boxSize,
  position,
  mass,
  quaternion,
  material,
  objects,
  cellCoordString,
) {
  const box = new THREE.Mesh(
    new THREE.BoxGeometry(2 * boxSize.x, 2 * boxSize.y, 2 * boxSize.z),
    material,
  );

  box.position.set(position.x, position.y, position.z);
  box.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w);

  const index = globalContext.createBoxBody(
    new Module.btVector3(boxSize.x, boxSize.y, boxSize.z),
    new Module.btVector3(position.x, position.y, position.z),
    mass,
    new Module.btQuaternion(
      quaternion.x,
      quaternion.y,
      quaternion.z,
      quaternion.w,
    ),
  );
  objects.push({
    isGround: true,
    type: "physical",
    object: box,
    index: index,
    transform: new Module.btTransform(),
    cellCoordString: cellCoordString,
  });
}

function createModelObject(
  model,
  position,
  mass,
  quaternion,
  material,
  objects,
  cellCoordString,
  type,
) {
  model.computeBoundingBox();
  model.center();
  const boxSize = model.boundingBox.getSize(new THREE.Vector3());

  boxSize.x *= types[type].boundingBoxMultiplier.x;
  boxSize.y *= types[type].boundingBoxMultiplier.y;
  boxSize.z *= types[type].boundingBoxMultiplier.z;

  const box = new THREE.Mesh(model, material);

  const multiplier = 1;
  const box2 = new THREE.Mesh(
    new THREE.BoxGeometry(
      multiplier * boxSize.x,
      multiplier * boxSize.y,
      multiplier * boxSize.z,
    ),
    material.clone(), //material,
  );

  box2.material.transparent = true;
  box2.material.opacity = 0.4;
  box.position.set(position.x, position.y, position.z);
  box.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w);

  //box.add(box2);
  const index = globalContext.createBoxBody(
    new Module.btVector3(
      (multiplier * boxSize.x) / 2,
      (multiplier * boxSize.y) / 2,
      (multiplier * boxSize.z) / 2,
    ),
    new Module.btVector3(position.x, position.y, position.z),
    mass,
    new Module.btQuaternion(
      quaternion.x,
      quaternion.y,
      quaternion.z,
      quaternion.w,
    ),
  );
  objects.push({
    isGround: false,
    type: "physical",
    object: box,
    index: index,
    transform: new Module.btTransform(),
    moving: false,
    cellCoordString: cellCoordString,
  });
}

function createComplexModelCollectable(
  model,
  position,
  mass,
  quaternion,
  materials,
  objects,
  cellCoordString,
  type,
) {
  // model.computeBoundingBox();
  // model.center();
  // const boxSize = model.boundingBox.getSize(new THREE.Vector3());

  const box = model.clone();
  box.children[0].material = materials[0];
  box.children[1].material = materials[1];
	box.scale.set(10, 10, 10)

  // const box = new THREE.Mesh(model, material);
  materials[0].transparent = true;
  materials[1].transparent = true;

  // const box2 = new THREE.Mesh(
  //   new THREE.BoxGeometry(2 * boxSize.x, 2 * boxSize.y, 2 * boxSize.z),
  //   material,
  // );

  box.position.set(position.x, position.y, position.z);
  box.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w);

  objects.push({
    type: "collectable",
    object: box,
    alive: true,
    collected: false,
    targetY: 0,
    sourceY: 0,
    time: 0.0,
    cellCoordString: cellCoordString,
    collectionCallback: collectablesCallback[type],
    // reward: collectablesReward[type],
  });
}

function createModelCollectable(
  model,
  position,
  mass,
  quaternion,
  material,
  objects,
  cellCoordString,
  type,
) {
  // model.computeBoundingBox();
  // model.center();
  // const boxSize = model.boundingBox.getSize(new THREE.Vector3());

  const box = new THREE.Mesh(model, material);
  material.transparent = true;

  // const box2 = new THREE.Mesh(
  //   new THREE.BoxGeometry(2 * boxSize.x, 2 * boxSize.y, 2 * boxSize.z),
  //   material,
  // );

  box.position.set(position.x, position.y, position.z);
  box.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w);

  objects.push({
    type: "collectable",
    object: box,
    alive: true,
    collected: false,
    targetY: 0,
    sourceY: 0,
    time: 0.0,
    cellCoordString: cellCoordString,
    collectionCallback: collectablesCallback[type],
    // reward: collectablesReward[type],
  });
}

function updateMovingPhysicalObject(object) {
  if (object.index == -1) return;

  const transform = object.transform;
  globalContext.getMovingBodyTransform(object.index, transform);
  const velocity = globalContext.getLinearVelocityOfMovingObject(object.index);
  updatePhysicalObject(object, transform);
  if (velocity < 0.5) {
    moveFromMovingToCell(object, globalContext);
  }
}

function updateCellPhysicalObject(object) {
  const transform = object.transform;
  globalContext.getCellBodyTransform(object.index, transform);
  const velocity = globalContext.getLinearVelocityOfCellObject(object.index);
  if (velocity > 0.5) {
    moveFromCellToMoving(object, globalContext);
    const index = Math.min(Math.floor(Math.random() * 5) + 1, 5);
    const vehicles = getVehicles();

    const distanceVector = new THREE.Vector3();
    distanceVector.copy(vehicles[0].chassis.position);
    distanceVector.sub(object.object.position);
    const length = distanceVector.length();
    if (length < 30.0) {
      //console.log(length);
      const volume = 0.3 * (1 / (0.2 * length + 1));
      playSound("Hit" + index, volume);
    }
  }
  updatePhysicalObject(object, transform);
}

function updatePhysicalObject(object, transform) {
  const quat = transform.getRotation();
  const position = transform.getOrigin();

  object.object.quaternion.set(quat.x(), quat.y(), quat.z(), quat.w());
  object.object.position.set(position.x(), position.y(), position.z());

  // const vehicles = getVehicles();
  //
  // const distanceVector = new THREE.Vector3();
  // distanceVector.copy(vehicles[0].chassis.position);
  // distanceVector.sub(object.object.position);
  // const length = distanceVector.length();
  // const distanceBetweenCenters =
  //   object.object.geometry.boundingSphere.radius + 2;
  //
  // if (length < distanceBetweenCenters) {
  // playSound("Hit");
  //}
}

function updateCollectableObject(object, vehicle) {
  if (!object.alive) return;
  const position = vehicle.position;
  distanceVector.copy(position);
  distanceVector.sub(object.object.position);
  distanceVector.y = 0.0;
  const distance = distanceVector.length();
  4;
  if (distance < 8.5) {
    if (!object.collected && !getDeath()) {
      // data.score += isStartScreen() ? object.reward : 0.0;
      // playSound("collectTrash");
      object.collectionCallback();
    }
    object.collected = true;
    object.sourceY = object.object.position.y;
    object.targetY = position.y;
  } else {
    object.object.rotation.y += 0.02;
  }
  if (object.collected) {
    const velocityTime = 0.04;
    const velocity = 0.4; //0.05;
    object.time += velocityTime;
    const scaleOpacity = Math.pow(1.0 - object.time, 0.4);
    //object.object.material.opacity = Math.pow(1.0 - object.time, 0.4);
    object.object.scale.set(scaleOpacity, scaleOpacity, scaleOpacity);
    object.object.position.y =
      object.sourceY +
      3.0 * object.time -
      (10.0 * Math.pow(object.time, 2.0)) / 2.0;
    object.object.position.x +=
      velocity * (position.x - object.object.position.x);
    object.object.position.z +=
      velocity * (position.z - object.object.position.z);
  }

  if (object.time > 1.0) {
    object.alive = false;
  }
}

function updateCellObject(object, vehicle) {
  if (object.type === "physical") updateCellPhysicalObject(object);
  else updateCollectableObject(object, vehicle);
}

export {
  data,
  injectGlobalContext,
  createBoxObject,
  createModelObject,
  updateCellObject,
  updateMovingPhysicalObject,
  createModelCollectable,
  createComplexModelCollectable,
};
