import * as THREE from "three";
import { pointMaterial } from "./pointsMaterial";

const DefaultParticleSystemConfig = () => {
  return {
    name: "",
    N: { value: 100, info: { min: 0, max: 1000, step: 1 } },
    map: { value: "particle", info: "texture" },
    meshVisible: { value: false, info: {} },
    size: { value: 1.0, info: { min: 0, max: 10 } },
    velocity: {
      value: { x: 0, y: 0, z: -0.4 },
      info: {
        x: { min: -20, max: 20 },
        y: { min: -20, max: 20 },
        z: { min: -20, max: 20 },
      },
    },
    scale: {
      value: { x: 1, y: 1, z: 1 },
      info: {
        x: { min: 0, max: 20 },
        y: { min: 0, max: 20 },
        z: { min: 0, max: 20 },
      },
    },
    position: {
      value: { x: 0, y: 0, z: 0 },
      info: {
        x: { min: -20, max: 20 },
        y: { min: -20, max: 20 },
        z: { min: -20, max: 20 },
      },
    },
    rotation: {
      value: { x: 0, y: 0, z: 0 },
      info: {
        x: { min: -Math.PI, max: Math.PI },
        y: { min: -Math.PI, max: Math.PI },
        z: { min: -Math.PI, max: Math.PI },
      },
    },
  };
};

export class ParticleSystem {
  constructor(name, data = null, config = DefaultParticleSystemConfig()) {
    this.name = name;
    this.data = data;
    this.config = config;
    this.config.name = name;

    this.N = this.config.N.value;
    const N = this.N;
    this.pointer = 0;
    this.times = new Float32Array(N);
    this.alphas = new Float32Array(N);
    this.positions = new Float32Array(3 * N);
    this.velocities = new Float32Array(3 * N);

    this.forward = false;
    this.s = 0;

    for (let i = 0; i < N; i++) {
      this.times[i] = -1;
      this.alphas[i] = 1;
    }

    this.geometry = new THREE.BufferGeometry();
    this.geometry.setAttribute(
      "position",
      new THREE.BufferAttribute(this.positions, 3),
    );
    this.geometry.setAttribute(
      "velocity",
      new THREE.BufferAttribute(this.velocities, 3),
    );

    this.geometry.setAttribute(
      "time",
      new THREE.BufferAttribute(this.times, 1),
    );

    this.geometry.setAttribute(
      "alpha",
      new THREE.BufferAttribute(this.times, 1),
    );

    this.material = pointMaterial();
    this.points = new THREE.Points(this.geometry, this.material);
    this.points.frustumCulled = false;

    this.emitter = new THREE.BoxGeometry(1, 1, 1, 5, 5, 5);
    this.emitterMesh = new THREE.Mesh(
      this.emitter,
      new THREE.MeshBasicMaterial({ color: "white" }),
    );
  }
  emit() {
    this.pointer = this.pointer % this.N;

    const randomIndex = Math.floor(
      Math.random() * this.emitter.attributes.position.count,
    );
    const positionX = this.emitter.attributes.position.array[3 * randomIndex];
    const positionY =
      this.emitter.attributes.position.array[3 * randomIndex + 1];
    const positionZ =
      this.emitter.attributes.position.array[3 * randomIndex + 2];

    this.positions[3 * this.pointer] =
      positionX + Math.random() * this.config.velocity.value.x;
    this.positions[3 * this.pointer + 1] =
      positionY + Math.random() * this.config.velocity.value.y;
    this.positions[3 * this.pointer + 2] =
      positionZ + Math.random() * this.config.velocity.value.z;

    this.velocities[3 * this.pointer] =
      this.config.velocity.value.x + 0.4 * (Math.random() - 0.5);
    this.velocities[3 * this.pointer + 1] =
      this.config.velocity.value.y + 0.4 * (Math.random() - 0.5);
    this.velocities[3 * this.pointer + 2] =
      this.config.velocity.value.z + 0.4 * (Math.random() - 0.5);

    this.times[this.pointer] = 0;
    // this.alphas[this.pointer] = dot;

    this.pointer++;

    this.geometry.attributes["position"].needsUpdate = true;
    this.geometry.attributes["velocity"].needsUpdate = true;
    this.geometry.attributes["alpha"].needsUpdate = true;
  }

  setData(data) {
    this.data = data;
    if (data) this.setConfigParameters();
  }

  addGUI(node) {
    const subnode = node.addFolder({ title: this.name });
    Object.keys(this.config).forEach((key) => {
      if (key === "name") return;
      const entry = this.config[key];
      if (entry.info != "texture" && entry.info != "model") {
        subnode
          .addInput(entry, "value", { ...entry.info, label: key })
          .on("change", () => {
            this.setConfigParameters();
          });
      } else
        subnode
          .addInput(entry, "value", {
            label: key,
            options: Object.keys(this.data.textures).reduce(
              (options, current) => {
                const o = {};
                o[current] = current;
                return { ...options, ...o };
              },
              {},
            ),
          })
          .on("change", () => {
            this.setConfigParameters();
          });
    });
  }

  setConfigParameters() {
    const mapName = this.config.map.value;
    this.points.material.uniforms.map.value = this.data.textures[mapName];
    this.points.material.uniforms.u_size.value = this.config.size.value;

    this.emitter = new THREE.BoxGeometry(1, 1, 1, 5, 5, 5);
    this.emitter.scale(
      this.config.scale.value.x,
      this.config.scale.value.y,
      this.config.scale.value.z,
    );
    this.emitter.rotateX(this.config.rotation.value.x);
    this.emitter.rotateY(this.config.rotation.value.y);
    this.emitter.rotateZ(this.config.rotation.value.z);
    this.emitter.translate(
      this.config.position.value.x,
      this.config.position.value.y,
      this.config.position.value.z,
    );
    this.emitter.attributes.position.needsUpdate = true;
    this.emitterMesh.geometry = this.emitter;
    this.emitterMesh.visible = this.config.meshVisible.value;
  }

  setMap(map) {
    this.points.material.uniforms.map.value = map;
  }

  addToScene(object) {
    object.add(this.points);
  }
  activate() {
    this.active = true;
    // this.points.visible = true;
    this.forward = true;
  }

  deactivate() {
    this.active = false;
    this.forward = false;
    // this.points.visible = false;
  }
  togglePeriodicity() {
    this.periodic = !this.periodic;
  }
  getType() {
    return "ParticleSystem";
  }

  update() {
    this.emit();
    for (let i = 0; i < this.N; i++) {
      if (this.times[i] != -1) this.times[i] += 0.005;
      if (this.times[i] > 1) this.times[i] = -1;
    }

    this.s += 0.016 * (this.forward ? 1.0 : -1.0);
    this.s = Math.max(Math.min(1.0, this.s), 0.0);

    this.points.material.uniforms.s.value = this.s;

    this.geometry.attributes["time"].needsUpdate = true;
  }
}
