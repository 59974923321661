import { Howl, Howler } from "howler";
import { areEnemiesAdded } from "./module";
import { assets } from "./loader";

const soundBuffers = assets.sounds;
let sounds;
let soundOn = true;

function bufferToDataURL(mimeType, buffer) {
  return `data:${mimeType};base64,${buffer.toString("base64")}`;
}

export function initSound() {
  sounds = {
    collectTrash: new Howl({
      src: [soundBuffers["collectTrash"]], //"sounds/pickupTrash.wav"],
      volume: 0.1,
      onplayerror: function () {
        let sound = sounds["collectTrash"];
        sound.once("unlock", function () {
          sound.play();
        });
      },
    }),
    Engine0: new Howl({
      src: [soundBuffers["engine"]],
      loop: true,
      volume: 0.0,
      //html5: true,
    }),
    Engine1: new Howl({
      src: [soundBuffers["engine"]],
      loop: true,
      volume: 0.0,
      //html5: true,
    }),
    Engine2: new Howl({
      src: [soundBuffers["engine"]],
      loop: true,
      volume: 0.0,
      //html5: true,
    }),
    Engine3: new Howl({
      src: [soundBuffers["engine"]],
      loop: true,
      volume: 0.0,
      //html5: true,
    }),
    Engine4: new Howl({
      src: [soundBuffers["engine"]],
      loop: true,
      volume: 0.0,
      //html5: true,
    }),
    Engine5: new Howl({
      src: [soundBuffers["engine"]],
      loop: true,
      volume: 0.0,
      //html5: true,
    }),
    Engine6: new Howl({
      src: [soundBuffers["engine"]],
      loop: true,
      volume: 0.0,
      //html5: true,
    }),
    Engine7: new Howl({
      src: [soundBuffers["engine"]],
      loop: true,
      volume: 0.0,
      //html5: true,
    }),
    Engine8: new Howl({
      src: [soundBuffers["engine"]],
      loop: true,
      volume: 0.0,
      //html5: true,
    }),
    Engine9: new Howl({
      src: [soundBuffers["engine"]],
      loop: true,
      volume: 0.0,
      //html5: true,
    }),
    Engine10: new Howl({
      src: [soundBuffers["engine"]],
      loop: true,
      volume: 0.0,
      //html5: true,
    }),
    Drift1: new Howl({
      src: [soundBuffers["drift1"]], //["sounds/screeches/screech-1.wav"],
      loop: false,
      volume: 0.0,
    }),
    Drift2: new Howl({
      src: [soundBuffers["drift2"]], //["sounds/screeches/screech-1.wav"],
      loop: false,
      volume: 0.1,
    }),
    Drift3: new Howl({
      src: [soundBuffers["drift3"]], //["sounds/screeches/screech-1.wav"],
      loop: false,
      volume: 0.1,
    }),
    Hit1: new Howl({
      src: [soundBuffers["hit1"]],
      loop: false,
      volume: 0.0,
    }),
    Hit2: new Howl({
      src: [soundBuffers["hit2"]],
      loop: false,
      volume: 0.0,
    }),
    Hit3: new Howl({
      src: [soundBuffers["hit3"]],
      loop: false,
      volume: 0.0,
    }),
    Hit4: new Howl({
      src: [soundBuffers["hit4"]],
      loop: false,
      volume: 0.0,
    }),
    Hit5: new Howl({
      src: [soundBuffers["hit5"]],
      loop: false,
      volume: 0.0,
    }),
    HitHurt: new Howl({
      src: [soundBuffers["hitHurt"]],
      loop: false,
      volume: 0.0,
    }),
  };
}

export function toggleSound() {
  soundOn = !soundOn;
  if (soundOn) Howler.volume(1.0);
  else Howler.volume(0.0);
}

export function setSound(value) {
  soundOn = value;
  if (soundOn) Howler.volume(1.0);
  else Howler.volume(0.0);
}

export function playSound(name, volume) {
  if (!areEnemiesAdded()) return;
  // console.log(sounds[name].playing());
  if (!sounds[name].playing()) sounds[name].play();
  if (typeof volume == "number") sounds[name].volume(volume);
}
export function playSoundNoCheck(name, volume, index) {
  if (!areEnemiesAdded()) return;
  //console.log(sounds[name].playing(index));
  let rIndex = index;
  if (index == -1 || !sounds[name].playing(index)) rIndex = sounds[name].play();
  if (typeof volume == "number") {
    let prevVolume = sounds[name].volume();
    sounds[name].volume(volume, rIndex);
    //sounds[name].fade(prevVolume, volume, 10);
  }

  return rIndex;
}

export function setVolume(name, volume) {
  const prevVolume = sounds[name].volume();
  //console.log(prevVolume);
  if (typeof volume == "number") {
    sounds[name].volume(volume);
    //sounds[name].fade(prevVolume, volume, 15);
  }
}
export function setRate(name, rate) {
  if (typeof rate == "number") {
    sounds[name].rate(rate);
    //sounds[name].fade(prevVolume, volume, 15);
  }
}

export function stopSound(name) {
  sounds[name].stop();
}
export function pauseSound(name) {
  sounds[name].pause();
}
