import * as THREE from "three";
import { sphereMaterial } from "./sphereMaterial";

const DefaultProjectileSphereConfig = () => {
  return {
    name: "",
    map: { value: "accel", info: "texture" },
    scale: {
      value: { x: 6, y: 6, z: 6 },
      info: {
        x: { min: 0, max: 20 },
        y: { min: 0, max: 20 },
        z: { min: 0, max: 20 },
      },
    },
    position: {
      value: { x: 0, y: 0, z: -12 },
      info: {
        x: { min: -20, max: 20 },
        y: { min: -20, max: 20 },
        z: { min: -20, max: 20 },
      },
    },
    rotation: {
      value: { x: Math.PI / 2, y: 0, z: 0 },
      info: {
        x: { min: -Math.PI, max: Math.PI },
        y: { min: -Math.PI, max: Math.PI },
        z: { min: -Math.PI, max: Math.PI },
      },
    },
  };
};

function rotationTransform(rotation) {
  return { _x: rotation.x, _y: rotation.y, _z: rotation.z, _order: "XYZ" };
}

export class ProjectileSphere {
  constructor(name, data, config = DefaultProjectileSphereConfig()) {
    this.sphere = new THREE.Mesh(
      new THREE.SphereGeometry(1, 64, 64, 0, 2 * 3.14, 0, 3.14 / 2),
      sphereMaterial(),
    );
    this.sphere.scale.set(
      config.scale.value.x,
      config.scale.value.y,
      config.scale.value.z,
    );
    this.sphere.rotation.copy(rotationTransform(config.rotation.value));
    this.sphere.position.copy(config.position.value);
    this.sphere.renderOrder = 100;
    this.sphere.visible = true;

    this.active = false;
    this.periodic = false;

    this.name = name;
    this.config = config;
    this.data = data;
    this.config.name = name;

    this.forward = false;
    this.s = 0.0;
  }

  setData(data) {
    this.data = data;
    if (data) this.setConfigParameters();
  }

  addGUI(node) {
    const subnode = node.addFolder({ title: this.name });
    Object.keys(this.config).forEach((key) => {
      if (key === "name") return;
      const entry = this.config[key];
      if (entry.info != "texture" && entry.info != "model") {
        subnode
          .addInput(entry, "value", { ...entry.info, label: key })
          .on("change", () => {
            this.setConfigParameters();
          });
      } else
        subnode
          .addInput(entry, "value", {
            label: key,
            options: Object.keys(this.data.textures).reduce(
              (options, current) => {
                const o = {};
                o[current] = current;
                return { ...options, ...o };
              },
              {},
            ),
          })
          .on("change", () => {
            this.setConfigParameters();
          });
    });
  }

  setConfigParameters() {
    const mapName = this.config.map.value;
    this.sphere.material.uniforms.map.value = this.data.textures[mapName];
    this.sphere.scale.set(
      this.config.scale.value.x,
      this.config.scale.value.y,
      this.config.scale.value.z,
    );
    this.sphere.rotation.copy(rotationTransform(this.config.rotation.value));
    this.sphere.position.copy(this.config.position.value);
  }

  setMap(map) {
    this.sphere.material.uniforms.map.value = map;
  }

  addToScene(object) {
    object.add(this.sphere);
  }
  activate() {
    this.active = true;
    this.forward = true;
    // this.sphere.visible = true;
  }

  deactivate() {
    // this.active = false;
    this.forward = false;
    // this.sphere.visible = false;
  }
  togglePeriodicity() {
    this.periodic = !this.periodic;
  }
  getType() {
    return "ProjectileSphere";
  }
  update() {
    if (!this.active) return;

    this.s += 0.016 * (this.forward ? 1.0 : -1.0);
    this.s = Math.max(Math.min(1.0, this.s), 0.0);

    this.sphere.material.uniforms.s.value = this.s;
    this.sphere.material.uniforms.time.value += 0.005;
  }
}

// const particleSystem = new DriftParticleSystem(1000, scene);
