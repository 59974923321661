import * as THREE from "three";
import { ribbonMaterial } from "./ribbonMaterial";

const DefaultSpiralRibbonConfig = () => {
  return {
    name: "",
    map: { value: "heal", info: "texture" },
    slope0: { value: 4.78, info: { min: -20, max: 20 } },
    radius0: { value: 9.78, info: { min: 0, max: 20 } },
    position0: {
      value: { x: 0.0, y: 2.1, z: 0.0 },
      info: {
        x: { min: -10, max: 10 },
        y: { min: -10, max: 10 },
        z: { min: -10, max: 10 },
      },
    },
    slope1: { value: 0.22, info: { min: -20, max: 20 } },
    radius1: { value: 10.43, info: { min: 0, max: 20 } },
    position1: {
      value: { x: 1.0, y: 3.9, z: 0.0 },
      info: {
        x: { min: -10, max: 10 },
        y: { min: -10, max: 10 },
        z: { min: -10, max: 10 },
      },
    },
    position: {
      value: { x: 0, y: 0, z: 0 },
      info: {
        x: { min: -20, max: 20 },
        y: { min: -20, max: 20 },
        z: { min: -20, max: 20 },
      },
    },
    scale: {
      value: { x: 1, y: 1, z: 1 },
      info: {
        x: { min: 0, max: 20 },
        y: { min: 0, max: 20 },
        z: { min: 0, max: 20 },
      },
    },
    rotation: {
      value: { x: 0, y: 0, z: 0 },
      info: {
        x: { min: -Math.PI, max: Math.PI },
        y: { min: -Math.PI, max: Math.PI },
        z: { min: -Math.PI, max: Math.PI },
      },
    },
  };
};

function rotationTransform(rotation) {
  return { _x: rotation.x, _y: rotation.y, _z: rotation.z, _order: "XYZ" };
}

// [4.78, 9.78, new THREE.Vector3(0.0, 2.1, 0.0), 0]
// [(0.22, 10.43, new THREE.Vector3(1.0, 3.9, 0.0))]
export class SpiralRibbon {
  constructor(name, data, config = DefaultSpiralRibbonConfig()) {
    const ribbonGeometry = new THREE.PlaneGeometry(1.0, 1.0, 5, 128);
    const ribbon = new THREE.Mesh(
      ribbonGeometry,
      ribbonMaterial(
        {
          slope: config.slope0.value,
          radius: config.radius0.value,
          position: new THREE.Vector3().copy(config.position0.value),
        }, //curveData0: slope, radius, position
        {
          slope: config.slope1.value,
          radius: config.radius1.value,
          position: new THREE.Vector3().copy(config.position1.value),
        }, //curveData0: slope, radius, position
        data ? data.textures[config.map.value] : null,
      ),
    );

    ribbon.renderOrder = 102;
    this.ribbon = ribbon;
    // this.active = true;
    this.periodic = false;
    this.time = 0;

    this.forward = false;
    this.s = 0;

    this.data = data;
    this.config = config;
    this.config.name = name;
    this.name = name;

    this.ribbon.scale.set(
      this.config.scale.value.x,
      this.config.scale.value.y,
      this.config.scale.value.z,
    );
    this.ribbon.rotation.copy(rotationTransform(this.config.rotation.value));
    this.ribbon.position.copy(this.config.position.value);
  }

  setData(data) {
    this.data = data;
    if (data) this.setConfigParameters();
  }

  addGUI(node) {
    const subnode = node.addFolder({ title: this.name });
    Object.keys(this.config).forEach((key) => {
      if (key === "name") return;
      const entry = this.config[key];
      if (entry.info != "texture" && entry.info != "model") {
        subnode
          .addInput(entry, "value", { ...entry.info, label: key })
          .on("change", () => {
            this.setConfigParameters();
          });
      } else
        subnode
          .addInput(entry, "value", {
            label: key,
            options: Object.keys(this.data.textures).reduce(
              (options, current) => {
                const o = {};
                o[current] = current;
                return { ...options, ...o };
              },
              {},
            ),
          })
          .on("change", () => {
            this.setConfigParameters();
          });
    });
  }

  setConfigParameters() {
    const mapName = this.config.map.value;
    this.ribbon.material.uniforms.map.value = this.data.textures[mapName];
    this.ribbon.material.uniforms.slope0.value = this.config.slope0.value;
    this.ribbon.material.uniforms.radius0.value = this.config.radius0.value;
    this.ribbon.material.uniforms.circlePosition0.value.copy(
      this.config.position0.value,
    );

    this.ribbon.material.uniforms.slope1.value = this.config.slope1.value;
    this.ribbon.material.uniforms.radius1.value = this.config.radius1.value;
    this.ribbon.material.uniforms.circlePosition1.value.copy(
      this.config.position1.value,
    );

    this.ribbon.scale.set(
      this.config.scale.value.x,
      this.config.scale.value.y,
      this.config.scale.value.z,
    );
    this.ribbon.rotation.copy(rotationTransform(this.config.rotation.value));
    this.ribbon.position.copy(this.config.position.value);
  }

  setMap(map) {
    this.ribbon.material.uniforms.map.value = map;
  }

  addToScene(object) {
    object.add(this.ribbon);
  }
  activate() {
    // this.active = true;
    // this.ribbon.visible = true;
    this.time = 0;
    this.forward = true;
  }

  deactivate() {
    // this.active = false;
    this.forward = false;
    // this.ribbon.visible = false;
  }
  togglePeriodicity() {
    this.periodic = !this.periodic;
  }
  getType() {
    return "SpiralRibbon";
  }
  update() {
    // if (!this.active) return;

    let finished = false;
    if (this.time < 1.0 + 0.3) this.time += 0.007; //0.02;
    else finished = true;
    if (this.periodic) this.time = this.time % (1 + 0.3);
    this.ribbon.material.uniforms.time.value = this.time;
    // if (finished && !this.periodic) this.deactivate();

    this.s += 0.016 * (this.forward ? 1.0 : -1.0);
    this.s = Math.max(Math.min(1.0, this.s), 0.0);

    this.ribbon.material.uniforms.s.value = this.s;
  }
}

// const particleSystem = new DriftParticleSystem(1000, scene);
