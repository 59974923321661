import { SphereShield } from "./elements/SphereShield/SphereShield.js";
import { SpiralRibbon } from "./elements/SpiralRibbon/SpiralRibbon.js";
import { ForwardRibbon } from "./elements/ForwardRibbon/ForwardRibbon.js";
import { ProjectileSphere } from "./elements/ProjectileSphere/ProjectileSphere.js";
import { ParticleSystem } from "./elements/ParticleSystem/ParticleSystem.js";
import { RibbonParticleSystem } from "./elements/RibbonParticleSystem/RibbonParticleSystem.js";

// export class DataModule {
//   constructor(data) {
//     this.data = data;
//
//   }
//
//   addGUI(node) {
//     const dataFolder = node.addFolder({ title: "Data" });
//     // Object.keys(data.textures).forEach((textureName) => {
//     //   dataFolder.addInput();
//     // });
//     dataFolder.addButton({ title: "addTexture" }),
//   }
// }

const typeConstructorMap = {
  SphereShield: createSphereShield,
  SpiralRibbon: createSpiralRibbon,
  ForwardRibbon: createForwardRibbon,
  ProjectileSphere: createProjectileSphere,
  ParticleSystem: createParticleSystem,
  RibbonParticleSystem: createRibbonParticleSystem,
};

export class VFX {
  constructor(name, config = {}, data = null) {
    this.subeffects = [];
    this.data = data;
    this.config = config;
    this.name = name;
    this.object = null;
  }
  addToScene(object) {
    this.object = object;
    this.subeffects.forEach((subeffect) => {
      subeffect.addToScene(object);
    });
  }

  deactivate() {
    this.subeffects.forEach((s) => s.deactivate());
  }
  activate() {
    this.subeffects.forEach((s) => s.activate());
  }
  togglePeriodicity() {
    this.subeffects.forEach((s) => s.togglePeriodicity());
  }

  setData(data) {
    this.data = data;
    this.subeffects.forEach((subeffect) => {
      subeffect.setData(data);
    });
  }
  addGUI(node) {
    const toAdd = { subeffect: "SpiralRibbon", name: "subeffect" };

    this.folder = node.addFolder({ title: this.name });
    this.folder.addInput(toAdd, "subeffect", {
      // options: {
      //   SphereShield: "SphereShield",
      //   SpiralRibbon: "SpiralRibbon",
      //   ForwardRibbon: "ForwardRibbon",
      //   ProjectileSphere: "ProjectileSphere",
      // },
      options: Object.keys(typeConstructorMap).reduce((dict, key) => {
        const o = {};
        o[key] = key;
        return { ...dict, ...o };
      }, {}),
    });

    this.folder.addInput(toAdd, "name");
    this.folder.addButton({ title: "add" }).on("click", () => {
      if (this.config[toAdd.name]) return;
      const subeffect = typeConstructorMap[toAdd.subeffect](
        toAdd.name,
        this.data,
      );
      this.addSubeffect(subeffect);
      subeffect.addGUI(this.folder);
      subeffect.addToScene(this.object);
    });

    this.folder.addButton({ title: "clearStorage" }).on("click", () => {
      localStorage.removeItem("config");
    });
    this.folder.addButton({ title: "saveToStorage" }).on("click", () => {
      this.serialize(true);
    });

    this.subeffects.forEach((subeffect) => {
      subeffect.addGUI(this.folder);
    });
  }
  setConfigParameters() {
    this.subeffects.forEach((subeffect) => {
      subeffect.setConfigParameters();
    });
  }

  addSubeffect(subeffect) {
    if (this.config[subeffect.name]) return;
    subeffect.data = this.data;
    this.subeffects.push(subeffect);
    this.config[subeffect.name] = {
      config: subeffect.config,
      type: subeffect.getType(),
    };
    if (this.object) subeffect.addToScene(this.object);
  }

  update() {
    this.subeffects.forEach((subeffect) => subeffect.update());
  }

  serialize(saveToStorage) {
    const serialized = JSON.stringify(this.config);
    if (saveToStorage) {
      const localStorage = window.localStorage;
      localStorage.setItem("config", serialized);
    }
    return serialized;
  }

  recoverFromSerializedObject(data) {
    const config = JSON.parse(data);
    this.config = config;

    Object.keys(this.config).forEach((subeffectKey) => {
      const config = this.config[subeffectKey].config;
      const type = this.config[subeffectKey].type;
      const name = this.config[subeffectKey].config.name;
      const subeffect = typeConstructorMap[type](name, this.data, config);
      this.subeffects.push(subeffect);
      if (this.object) subeffect.addToScene(this.object);
    });

    this.setConfigParameters();
  }

  recoverFromStorage() {
    const localStorage = window.localStorage;
    const data = localStorage.getItem("config");
    if (!data) return false;

    const config = JSON.parse(data);
    this.config = config;

    Object.keys(this.config).forEach((subeffectKey) => {
      const config = this.config[subeffectKey].config;
      const type = this.config[subeffectKey].type;
      const name = this.config[subeffectKey].config.name;
      const subeffect = typeConstructorMap[type](name, this.data, config);
      this.subeffects.push(subeffect);
      if (this.object) subeffect.addToScene(this.object);
    });

    this.setConfigParameters();
    return true;
  }
}

export function createVFX(name) {
  const vfx = new VFX(name);
  return vfx;
}

export function createSphereShield(name, data, config) {
  const shield = new SphereShield(name, data, config);
  shield.setData(data);
  return shield;
}

export function createSpiralRibbon(name, data, config) {
  const spiral = new SpiralRibbon(name, data, config);
  spiral.setData(data);
  return spiral;
}
export function createForwardRibbon(name, data, config) {
  const ribbon = new ForwardRibbon(name, data, config);
  ribbon.setData(data);
  return ribbon;
}
export function createProjectileSphere(name, data, config) {
  const sphere = new ProjectileSphere(name, data, config);
  sphere.setData(data);
  return sphere;
}
export function createParticleSystem(name, data, config) {
  const system = new ParticleSystem(name, data, config);
  system.setData(data);
  return system;
}
export function createRibbonParticleSystem(name, data, config) {
  const system = new RibbonParticleSystem(name, data, config);
  system.setData(data);
  return system;
}
