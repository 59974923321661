const state = {
  physics: {
    baseCar: {
      maxSpeed: 140,
      forwardForce: 4000,
      backwardForce: 3000,
      brakeForce: 60,
      mass: 800,
      collisionDamage: 0.013,
      bilateralCoefficientMin: 0.05,
      bilateralCoefficientMax: 0.5,
      angularCoefficient: 20,
      driftSpeed: 22,
    },
    enemyCar: {
      maxSpeed: 180, //165 200
      forwardForce: 7500, //5000 8400
      brakeForce: 1000,
      backwardForce: 0,
      mass: 1400, //800 1400
      collisionDamage: 0.02,
      bilateralCoefficientMin: 0.06,
      bilateralCoefficientMax: 0.5,
      angularCoefficient: 20,
      driftSpeed: 22,
    },
  },
  matcaps: {
    baseVehicle: "vehicle-base",
    enemyVehicle: "vehicle-enemy",
    ground: "ground",
    trash: "black",
    road_aware: "misc-3",
    road_blocker: "misc-3",
    trash_can: "misc-1",
    barrel: "misc-1",
    dump: "misc-2",
	  nitro: "nitro",
	  heal: "heal",
	  shield: "shield",
	  white: "white"
  },
};

const getState = () => {
  return state;
};

export { getState };
