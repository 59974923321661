import * as THREE from "three";
import vertex from "./sphereMaterial.glslv";
import fragment from "./sphereMaterial.glslf";

export const sphereMaterial = (map) => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    blending: THREE.NormalBlending,
    depthWrite: true,
    depthTest: true,
    // side: THREE.DoubleSide,
    uniforms: {
      time: { value: 0 },
      // slope: { value: slope },
      // radius: { value: radius },
      // length: { value: length },
      // width: { value: width },
      // circlePosition: { value: position },
      map: { value: map },
      // health: { value: 1 },
    },
  });
};
