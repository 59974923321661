import * as THREE from "three";
import vertex from "./armor.glslv";
import fragment from "./armor.glslf";

const armorMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    blending: THREE.NormalBlending,
    uniforms: {
      armor: { value: 1 },
    },
  });
};

export default armorMaterial;
