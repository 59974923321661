import * as THREE from "three";
import { getState } from "./state";
import { assets } from "./loader";

const state = getState();

const collectablesNames = [
  "Trash_01",
  "Trash_02",
  "Trash_03",
  "Trash_04",
  "Trash_05",
  "Trash_06",
];

const boxNames = ["WoodenBox_01", "WoodenBox_02"];

const nameToTypeDict = {
  Trash_01: "Trash",
  Trash_02: "Trash",
  Trash_03: "Trash",
  Trash_04: "Trash",
  Trash_05: "Trash",
  Trash_06: "Trash",
  Road_Aware_01: "Road_Aware",
  Road_Aware_02: "Road_Aware",
  Road_Blocker_01: "Road_Blocker",
  Road_Blocker_02: "Road_Blocker",
  Trash_Can_01: "Trash_Can",
  Trash_Can_02: "Trash_Can",
  Barrel_01: "Barrel",
  Barrel_03: "Barrel",
  Dump_01: "Dump",
  Dump_02: "Dump",
  Dump_03: "Dump",
  Dump_04: "Dump",
  Ground: "Ground",
  Heal: "Heal",
  Armor: "Armor",
  Nitro: "Nitro",
};

const materials = {
  Ground: new THREE.MeshMatcapMaterial({
    matcap: assets.matcaps[state.matcaps.ground],
  }),
  Trash: new THREE.MeshMatcapMaterial({
    matcap: assets.matcaps[state.matcaps.trash],
  }),
  Road_Aware: new THREE.MeshMatcapMaterial({
    matcap: assets.matcaps[state.matcaps.road_aware],
  }),
  Road_Blocker: new THREE.MeshMatcapMaterial({
    matcap: assets.matcaps[state.matcaps.road_blocker],
  }),
  Trash_Can: new THREE.MeshMatcapMaterial({
    matcap: assets.matcaps[state.matcaps.trash_can],
  }),
  Barrel: new THREE.MeshMatcapMaterial({
    matcap: assets.matcaps[state.matcaps.barrel],
  }),
  Dump: new THREE.MeshMatcapMaterial({
    matcap: assets.matcaps[state.matcaps.dump],
  }),

  //those are complex meshes with multiple materials
  Heal: [
    new THREE.MeshMatcapMaterial({
      matcap: assets.matcaps[state.matcaps.white],
    }),
    new THREE.MeshMatcapMaterial({
      matcap: assets.matcaps[state.matcaps.heal],
    }),
  ],
  Nitro: [
    new THREE.MeshMatcapMaterial({
      matcap: assets.matcaps[state.matcaps.white],
    }),
    new THREE.MeshMatcapMaterial({
      matcap: assets.matcaps[state.matcaps.nitro],
    }),
  ],
  Armor: [
    new THREE.MeshMatcapMaterial({
      matcap: assets.matcaps[state.matcaps.white],
    }),
    new THREE.MeshMatcapMaterial({
      matcap: assets.matcaps[state.matcaps.shield],
    }),
  ],
};

function getMaterial(name) {
  const type = nameToTypeDict[name];
  return materials[type];
}

function updateMaterials() {
  materials["Ground"].matcap = assets.matcaps[state.matcaps.ground];
  materials["Trash"].matcap = assets.matcaps[state.matcaps.trash];
  materials["Road_Aware"].matcap = assets.matcaps[state.matcaps.road_aware];
  materials["Road_Blocker"].matcap = assets.matcaps[state.matcaps.road_blocker];
  materials["Trash_Can"].matcap = assets.matcaps[state.matcaps.trash_can];
  materials["Barrel"].matcap = assets.matcaps[state.matcaps.barrel];
  materials["Dump"].matcap = assets.matcaps[state.matcaps.dump];
  materials["Heal"][0].matcap = assets.matcaps[state.matcaps.white];
  materials["Heal"][1].matcap = assets.matcaps[state.matcaps.heal];
  materials["Nitro"][0].matcap = assets.matcaps[state.matcaps.white];
  materials["Nitro"][1].matcap = assets.matcaps[state.matcaps.nitro];
  materials["Armor"][0].matcap = assets.matcaps[state.matcaps.white];
  materials["Armor"][1].matcap = assets.matcaps[state.matcaps.shield];
}

export { getMaterial, updateMaterials };
