import * as THREE from "three";
import Stats from "three/examples/jsm/libs/stats.module";
import pointMaterial from "./material/pointsMaterial";
import { loadAssets, assets } from "./loader";
import { injectGlobalContext, data } from "./objects";
import { updateGrid, initializeGridStructures } from "./gridSystem";
import { getState } from "./state";
import {
  createVehicleWithModel,
  updateVehicle,
  spawnVehicle,
  getVehicles,
} from "./vehicle";

let globalContext;
let toRender = true;
const renderer = new THREE.WebGLRenderer({
  antialias: true,
  precision: "lowp",
});
import { buildGUI } from "./gui";
import { updateMaterials } from "./materials";
import {
  playSound,
  stopSound,
  setVolume,
  initSound,
  toggleSound,
  setSound,
} from "./sound";
import { ArmorVFX } from "./vfx/armor/armor";
import { nitroConfig, healConfig } from "./loader";
import { createVFX } from "vfx/src/vfx";
import { Pane } from "tweakpane";

const state = getState();
const vehicles = getVehicles();

let cameraForward = new THREE.Vector3();
let gamepadIndex = null;
let pause = false;
let death = false;
let restarting = false;
let lastTime = 0.0;
let heapSize = 0;

let scoreText;
let camera;
const keyData = {
  isForwardPressed: false,
  isBackwardPressed: false,
  brakePressed: false,
};
const modelDataDict = {
  Musorovoz: {
    mesh: null,
    boundingRadius: 0,
    wheelRadius: 0,
    wheelPositionsMain: [
      new THREE.Vector3(0.5, -0.22, 0.04),
      new THREE.Vector3(0.5, 0.22, 0.04),

      new THREE.Vector3(-0.33, 0.22, 0.04),
      new THREE.Vector3(-0.33, -0.22, 0.04),
    ],
    wheels: [],
  },
  TankTruck: {
    mesh: null,
    boundingRadius: 0,
    wheelRadius: 0,
    wheelPositionsMain: [
      new THREE.Vector3(0.5, -0.22, 0.04),
      new THREE.Vector3(0.5, 0.22, 0.04),

      new THREE.Vector3(-0.33, 0.22, 0.04),
      new THREE.Vector3(-0.33, -0.22, 0.04),
    ],
    wheels: [],
  },
};
// const wheelPositionsMain = [
//   new THREE.Vector3(0.5, -0.22, 0.04),
//   new THREE.Vector3(0.5, 0.22, 0.04),
//
//   new THREE.Vector3(-0.33, 0.22, 0.04),
//   new THREE.Vector3(-0.33, -0.22, 0.04),
// ];

let enemiesAdded = false;
let startScreen = true;
let listenersActive = true;
let gamepadListenersActive = true;

let deathCallback = null;
let restartCallback = null;
let perFrameCallback = null;

renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(window.devicePixelRatio);

window.addEventListener("resize", (e) => {
  if (camera) {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
  }
  renderer.setSize(window.innerWidth, window.innerHeight);
});

const stats = new Stats();
// document.body.appendChild(stats.dom);

const scene = new THREE.Scene();
const heal = createVFX("heal");
const nitro = createVFX("nitro");
const armor = new ArmorVFX();

const plane = new THREE.Mesh(
  new THREE.PlaneGeometry(1000, 1000),
  new THREE.MeshMatcapMaterial(),
);

export function togglePause() {
  pause = !pause;
}

export function setPause(value) {
  pause = value;
}

export function getPause() {
  return pause;
}
export function setDeath(value) {
  death = value;
}

export function getDeath() {
  return death;
}

class DriftParticleSystem {
  constructor(N, scene) {
    this.N = N;
    this.pointer = 0;
    this.times = new Float32Array(N);
    this.alphas = new Float32Array(N);
    this.positions = new Float32Array(3 * N);
    this.velocities = new Float32Array(3 * N);

    for (let i = 0; i < N; i++) {
      this.times[i] = -1;
      this.alphas[i] = 1;
    }

    this.geometry = new THREE.BufferGeometry();
    this.geometry.setAttribute(
      "position",
      new THREE.BufferAttribute(this.positions, 3),
    );
    this.geometry.setAttribute(
      "velocity",
      new THREE.BufferAttribute(this.velocities, 3),
    );

    this.geometry.setAttribute(
      "time",
      new THREE.BufferAttribute(this.times, 1),
    );

    this.geometry.setAttribute(
      "alpha",
      new THREE.BufferAttribute(this.times, 1),
    );

    this.material = pointMaterial();
    this.points = new THREE.Points(this.geometry, this.material);
    this.points.frustumCulled = false;

    scene.add(this.points);
  }
  emit(position, velocity, dot) {
    this.pointer = this.pointer % this.N;

    const height = 0.0;

    this.positions[3 * this.pointer] =
      position.x + 0.5 * Math.random() * velocity.x;
    this.positions[3 * this.pointer + 1] =
      position.y + height + Math.random() * 0.2;
    this.positions[3 * this.pointer + 2] =
      position.z + 0.5 * Math.random() * velocity.z;

    this.velocities[3 * this.pointer] =
      velocity.x + 0.4 * (Math.random() - 0.5);
    this.velocities[3 * this.pointer + 1] = velocity.y + 0.3 * Math.random();
    this.velocities[3 * this.pointer + 2] =
      velocity.z + 0.4 * (Math.random() - 0.5);

    this.times[this.pointer] = 0;
    this.alphas[this.pointer] = dot;

    this.pointer++;

    this.geometry.attributes["position"].needsUpdate = true;
    this.geometry.attributes["velocity"].needsUpdate = true;
    this.geometry.attributes["alpha"].needsUpdate = true;
  }

  update() {
    for (let i = 0; i < this.N; i++) {
      if (this.times[i] != -1) this.times[i] += 0.005;
      if (this.times[i] > 1) this.times[i] = -1;
    }
    this.geometry.attributes["time"].needsUpdate = true;
  }
}

const particleSystem = new DriftParticleSystem(1000, scene);

function updateCamera() {
  const myVehicle = vehicles[0].chassis;

  cameraForward.set(0, 1, 0);
  cameraForward.applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI * 0.2);
  cameraForward.normalize();

  const distance = 70.0;

  camera.position.set(
    myVehicle.position.x + distance * cameraForward.x,
    distance * cameraForward.y,
    myVehicle.position.z + distance * cameraForward.z,
  );
  camera.lookAt(myVehicle.position);
}

function populateModelData() {
  ["Musorovoz", "TankTruck"].forEach((name) => {
    const modelData = modelDataDict[name];
    const chassis = assets[name].children[0];
    modelData.mesh = chassis;
    modelData.boundingRadius = chassis.geometry.boundingSphere.radius;

    const wheelDict = {
      1: 0,
      4: 1,
      3: 2,
      6: 3,
    };
    [1, 3, 4, 6].forEach((i, j) => {
      const wheel = assets[name].children[i];
      modelData.wheelRadius = wheel.geometry.boundingSphere.radius;
      modelData.wheelPositionsMain[wheelDict[i]].copy(wheel.position);
      wheel.geometry.center();
      modelData.wheels.push(wheel);
    });
  });
}

function buildScene(globalContext) {
  initializeGridStructures(scene, globalContext);

  camera = new THREE.PerspectiveCamera(
    60,
    window.innerWidth / window.innerHeight,
    // 0.01,
    // 1000,
    10,
    300,
  );
  camera.position.set(-50.0, 80.0, -50.0);

  // const chassis = assets.Musorovoz.children[0];
  // modelData.mesh = chassis;
  // modelData.boundingRadius = chassis.geometry.boundingSphere.radius;
  //
  // const wheelDict = {
  //   1: 0,
  //   4: 1,
  //   3: 2,
  //   6: 3,
  // };
  // [1, 3, 4, 6].forEach((i, j) => {
  //   const wheel = assets.Musorovoz.children[i];
  //   modelData.wheelRadius = wheel.geometry.boundingSphere.radius;
  //   wheelPositionsMain[wheelDict[i]].copy(wheel.position);
  //   wheel.geometry.center();
  //   modelData.wheels.push(wheel);
  // });
  populateModelData();

  const modelData = modelDataDict["Musorovoz"];
  createVehicleWithModel(
    modelData,
    modelData.wheelPositionsMain,
    modelData.wheelRadius,
    new THREE.Vector3(modelData.boundingRadius, 0.3, modelData.boundingRadius),
    new THREE.Vector3(0.0, 4.0, -5.0),
    800,
    new THREE.MeshMatcapMaterial({
      matcap: assets.matcaps[state.matcaps.baseVehicle],
    }),
    new THREE.MeshMatcapMaterial({ matcap: assets.matcaps.black }),
    vehicles,
    globalContext,
  );

  const v = globalContext.getVehicle(0);

  const options = new Module.VehicleOptions();
  Object.keys(state.physics.baseCar).forEach((key) => {
    options[key] = state.physics.baseCar[key];
  });

  v.setOptions(options);

  scene.add(vehicles[0].chassis);
  scene.add(vehicles[0].healthBar);
  scene.add(vehicles[0].armorBar);
  scene.add(vehicles[0].wheels[0].object);
  scene.add(vehicles[0].wheels[1].object);
  scene.add(vehicles[0].wheels[2].object);
  scene.add(vehicles[0].wheels[3].object);

  plane.material.matcap = assets.matcaps.ground;
  plane.rotation.x = -Math.PI / 2;
  scene.add(plane);

  camera.lookAt(vehicles[0].chassis.position);

  const data = { textures: assets.vfx };

  heal.setData(data);
  heal.addToScene(vehicles[0].chassis);
  heal.recoverFromSerializedObject(healConfig);

  nitro.setData(data);
  nitro.addToScene(vehicles[0].chassis);
  nitro.recoverFromSerializedObject(nitroConfig);

  armor.addToScene(vehicles[0].chassis);
  armor.setMap(assets.vfx.circle);

  updateMaterials();
}

export function runHeal() {
  heal.activate();
}

export function stopHeal() {
  heal.deactivate();
}

export function runNitro() {
  nitro.activate();
}

export function stopNitro() {
  nitro.deactivate();
}

export function upArmor() {
  armor.runUp();
}
export function downArmor() {
  armor.runDown();
}

export function isStartScreen() {
  return startScreen;
}

export function areEnemiesAdded() {
  return enemiesAdded;
}

function hideEnemies() {
  for (let i = 1; i < 11; i++) {
    const v = vehicles[i];
    v.chassis.visible = false;
    v.wheels[0].object.visible = false;
    v.wheels[1].object.visible = false;
    v.wheels[2].object.visible = false;
    v.wheels[3].object.visible = false;

    // console.log(v.index);
    const vehicle = globalContext.getVehicle(v.index);
    vehicle.deactivate();
  }
}

function showEnemies() {
  for (let i = 1; i < 11; i++) {
    const v = vehicles[i];
    v.chassis.visible = true;
    v.wheels[0].object.visible = true;
    v.wheels[1].object.visible = true;
    v.wheels[2].object.visible = true;
    v.wheels[3].object.visible = true;
  }
}

function toStartScreen() {
  restart(true);
}

export function addEnemies() {
  if (enemiesAdded) return;

  enemiesAdded = true;
  playSound("Engine" + 0);
  for (let i = 1; i < 11; i++) {
    const modelData =
      i < 6 ? modelDataDict["Musorovoz"] : modelDataDict["TankTruck"];
    createVehicleWithModel(
      modelData,
      modelData.wheelPositionsMain,
      modelData.wheelRadius,
      new THREE.Vector3(
        modelData.boundingRadius,
        0.3,
        modelData.boundingRadius,
      ),
      new THREE.Vector3(0.0, 0.0, 0.0),
      state.physics.enemyCar.mass,
      new THREE.MeshMatcapMaterial({
        matcap: assets.matcaps[state.matcaps.enemyVehicle],
      }),
      new THREE.MeshMatcapMaterial({ matcap: assets.matcaps.black }),
      vehicles,
      globalContext,
    );

    const v = globalContext.getVehicle(i);

    const options = new Module.VehicleOptions();
    Object.keys(state.physics.enemyCar).forEach((key) => {
      options[key] = state.physics.enemyCar[key];
    });

    v.setOptions(options);
  }

  vehicles.forEach((vehicle, i) => {
    if (i == 0) return;
    scene.add(vehicle.chassis);
    scene.add(vehicle.healthBar);
    scene.add(vehicle.armorBar);
    scene.add(vehicle.wheels[0].object);
    scene.add(vehicle.wheels[1].object);
    scene.add(vehicle.wheels[2].object);
    scene.add(vehicle.wheels[3].object);
    //spawnVehicle(vehicle, false, globalContext);
  });

  vehicles.forEach((vehicle, i) => {
    // if (i !== 0) spawnVehicle(vehicle, !vehicle.alive, globalContext);
    if (i !== 0) spawnVehicle(vehicle, globalContext);
  });
  // restart();
}

function handleGamepad(gamepad) {
  if (!gamepadListenersActive) return;
  const axes = gamepad.axes;
  const x = axes[0];
  let y = axes[1];

  // gamepad.buttons.forEach((b, i) => {
  //   if (b.pressed) console.log(b, i);
  // });

  const vehicle = globalContext.getVehicle(0);

  const engineButton = gamepad.buttons[7];
  const backButton = gamepad.buttons[6];
  const brakeButton = gamepad.buttons[2];
  const startButton = gamepad.buttons[0];

  if (engineButton.pressed) vehicle.engineForward(engineButton.value);
  else if (backButton.pressed) vehicle.engineBackward(backButton.value);
  else vehicle.resetEngineForce();

  if (brakeButton.pressed) {
    vehicle.brake();
  }
  if (startButton.pressed) {
    restart();
    // if (enemiesAdded) restart();
    // else addEnemies();
  }

  vehicle.setSteering(-x);
}

function preinitialize(url) {
  return Promise.all([loadAssets(url), createMyModule()]);
}

function restart(isStartScreen = false) {
  if (restarting) return;

  restarting = true;
  if (restartCallback) restartCallback();
  data.score = 0.0;
  const baseVehicle = globalContext.getVehicle(0);
  const spawnPosition = new Module.btVector3(0.0, 4.0, 0.0);
  vehicles[0].chassis.position.set(0.0, 0.0, 0.0);

  globalContext.cleanNonVehicleObjects();

  baseVehicle.setPosition(spawnPosition);
  baseVehicle.setHealth(1.0);
  baseVehicle.setArmor(0.0);

  vehicles[0].targetScale = 0.0;
  vehicles.forEach((vehicle, i) => {
    // if (i !== 0) spawnVehicle(vehicle, !vehicle.alive, globalContext);
    if (i !== 0) spawnVehicle(vehicle, globalContext);
  });

  if (!isStartScreen) {
    showEnemies();
    startScreen = false;
    setSound(true);
  } else {
    hideEnemies();
    startScreen = true;
    setSound(false);
  }

  pause = false;
  initializeGridStructures(scene, globalContext);
  vehicles[0].chassis.scale.set(0.001, 0.001, 0.001);
  vehicles[0].targetScale = 1.0;
  // vehicles[0].alive = true;
  // if (death) $(".gameover").toggleClass("gameover-hidden");
  if (death) baseVehicle.activate();

  death = false;

  setTimeout(() => {
    restarting = false;
  }, 1000);
}

function start(className) {
  initSound();

  document.querySelector(className).appendChild(renderer.domElement);

  // renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMapping = THREE.LinearToneMapping;
  renderer.toneMappingExposure = 1.1;
  //
  // const eObject = { exposure: 1.1, toneMapping: "Linear" };
  // const pane = new Pane();
  // pane.addInput(eObject, "exposure", { min: 0, max: 5 }).on("change", () => {
  //   renderer.toneMappingExposure = eObject.exposure;
  // });
  // pane
  //   .addInput(eObject, "toneMapping", {
  //     options: {
  //       No: "No",
  //       Filmic: "Filmic",
  //       Linear: "Linear",
  //       Reinhard: "Reinhard",
  //       Cineon: "Cineon",
  //     },
  //   })
  //   .on("change", () => {
  //     const dict = {
  //       No: THREE.NoToneMapping,
  //       Filmic: THREE.ACESFilmicToneMapping,
  //       Linear: THREE.LinearToneMapping,
  //       Reinhard: THREE.ReinhardToneMapping,
  //       Cineon: THREE.CineonToneMapping,
  //     };
  //     renderer.toneMapping = dict[eObject.toneMapping];
  //   });

  // document.body.appendChild(renderer.domElement);

  renderer.setSize(window.innerWidth, window.innerHeight);
  particleSystem.material.uniforms.sprite.value = assets.smokeTexture;

  globalContext = new Module.globalContext();
  injectGlobalContext(globalContext);
  globalContext.init();

  buildScene(globalContext);
  //buildGUI(globalContext, { restart });

  //values used to not allocate new objects
  const direction = new THREE.Vector3(1.0, 0.0, 0.0);
  const btDirection = new Module.btVector3();
  let counter = 0;

  playSound("collectTrash");
  addEnemies();
  hideEnemies();
  startScreen = true;
  setSound(false);

  const animate = () => {
    if (!toRender) {
      requestAnimationFrame(animate);
      return;
    }
    if (Module.HEAP8.length != heapSize) {
      console.log("New heap size is ", Module.HEAP8.length);
      heapSize = Module.HEAP8.length;
    }

    //keyboard controls handle
    const vehicle = globalContext.getVehicle(0);

    if (keyData.isForwardPressed) {
      //stopSound("Idle");
      //playSound("Engine");
      vehicle.engineForward(!startScreen ? 1.0 : 0.2);
    } else if (keyData.isBackwardPressed) {
      //stopSound("Idle");
      //playSound("Engine");
      vehicle.engineBackward(1.0);
    } else {
      //stopSound("Engine");
      //playSound("Idle");
      vehicle.resetEngineForce();
    }
    if (keyData.brakePressed) {
      vehicle.brake();
    }

    if (typeof gamepadIndex === "number") {
      const gamepad = navigator.getGamepads()[gamepadIndex];
      handleGamepad(gamepad);
    }

    if (startScreen) vehicle.engineForward(enemiesAdded ? 1.0 : 0.2);

    //if (!death) {
    updateGrid(vehicles[0].chassis, scene, globalContext);

    //make ai vehicles follow base car
    for (let i = 1; i < vehicles.length; i++) {
      const attackingVehicle = globalContext.getVehicle(i);
      if (!startScreen)
        attackingVehicle.aiDestructionSteering(
          vehicles[0].transform.getOrigin(),
        );
    }

    for (let i = 0; i < vehicles.length; i++)
      //stopSound("Idle");
      //playSound("Engine");
      updateVehicle(
        vehicles[i],
        counter,
        direction,
        btDirection,
        particleSystem,
        deathCallback,
        globalContext,
      );

    // $("[data-score]").text(data.score);
    particleSystem.update();
    updateCamera();
    plane.position.copy(vehicles[0].chassis.position);
    plane.position.y = 0.0;

    let currentTime = performance.now();
    let delta = 0.001 * (currentTime - lastTime);
    if (!pause) {
      globalContext.step(death ? 0.4 * delta : 1.9 * delta);
    }
    lastTime = currentTime;
    //}
    heal.update();
    nitro.update();
    armor.update();
    renderer.render(scene, camera);
    stats.update();
    counter++;

    if (perFrameCallback) perFrameCallback();

    requestAnimationFrame(animate);
  };

  window.addEventListener("keydown", (e) => {
    if (!listenersActive) return;
    const vehicle = globalContext.getVehicle(0);
    switch (e.code) {
      case "Enter":
        // if (!enemiesAdded) addEnemies();
        // else if (death) restart();
        if (death || startScreen) restart();
        break;
      case "ShiftRight":
      case "ShiftLeft":
        keyData.brakePressed = true;
        break;
      case "KeyR":
        if (enemiesAdded) restart();
        break;
      case "ArrowDown":
      case "KeyS":
        keyData.isBackwardPressed = true;
        break;
      case "ArrowUp":
      case "KeyW":
        keyData.isForwardPressed = true;
        break;
      case "ArrowLeft":
      case "KeyA":
        vehicle.setSteering(0.9);
        break;
      case "ArrowRight":
      case "KeyD":
        vehicle.setSteering(-0.9);
        break;
      default:
        break;
    }
  });

  window.addEventListener("keyup", (e) => {
    if (!listenersActive) return;
    const vehicle = globalContext.getVehicle(0);
    switch (e.code) {
      case "ShiftRight":
      case "ShiftLeft":
        keyData.brakePressed = false;
        break;
      case "ArrowDown":
      case "KeyS":
        keyData.isBackwardPressed = false;
        break;
      case "ArrowUp":
      case "KeyW":
        keyData.isForwardPressed = false;
        break;
      case "ArrowLeft":
      case "ArrowRight":
      case "KeyA":
      case "KeyD":
        vehicle.setSteering(0.0);
        break;
      default:
        break;
    }
  });

  animate();
}

window.addEventListener("gamepadconnected", (e) => {
  gamepadIndex = e.gamepad.index;
  console.log(
    "Gamepad connected at index %d: %s. %d buttons, %d axes.",

    e.gamepad.index,

    e.gamepad.id,

    e.gamepad.buttons.length,

    e.gamepad.axes.length,
  );
});
window.addEventListener("gamepaddisconnected", (event) => {
  console.log("disconnected");

  gamepadIndex = null;
});

function load(url) {
  return preinitialize(url).then((e) => {
    //set emscripten module visible from everywhere
    window.Module = e[1];
  });
}
function setListenersActive(value) {
  listenersActive = value;
}

function getScore() {
  return data.score;
}

function onDeath(callback) {
  deathCallback = callback;
}

function onRestart(callback) {
  restartCallback = callback;
}

function onFrame(callback) {
  perFrameCallback = callback;
}

function toggleRender() {
  toRender = !toRender;
  if (toRender && !isStartScreen()) {
    setSound(true);
  } else {
    setSound(false);
  }
}

function setGamepadListenersActive(value) {
  gamepadListenersActive = value;
}

export const TrashCarModule = {
  load: load,
  start: start,
  restart: restart,
  setListenersActive: setListenersActive,
  setGamepadListenersActive: setGamepadListenersActive,
  toStartScreen: toStartScreen,
  getScore: getScore,
  getDeath: getDeath,
  onDeath: onDeath,
  onRestart: onRestart,
  onFrame: onFrame,
  isStartScreen: isStartScreen,
  toggleRender: toggleRender,
};

window.trashCarModule = TrashCarModule;
