import * as THREE from "three";
import { ribbonMaterial } from "./ribbonMaterial";

const DefaultForwardRibbonConfig = () => {
  return {
    name: "",
    map: { value: "lighting", info: "texture" },
    length: { value: 10, info: { min: 0.0, max: 20.0 } },
    width: { value: 1.6, info: { min: 0.0, max: 20.0 } },
    position: {
      value: { x: -1.2, y: -0.6, z: -12.7 },
      info: {
        x: { min: -20, max: 20 },
        y: { min: -20, max: 20 },
        z: { min: -20, max: 20 },
      },
    },
    scale: {
      value: { x: 1, y: 1, z: 1 },
      info: {
        x: { min: 0, max: 20 },
        y: { min: 0, max: 20 },
        z: { min: 0, max: 20 },
      },
    },
    rotation: {
      value: { x: 0, y: 0, z: 0 },
      info: {
        x: { min: -Math.PI, max: Math.PI },
        y: { min: -Math.PI, max: Math.PI },
        z: { min: -Math.PI, max: Math.PI },
      },
    },
  };
};

function rotationTransform(rotation) {
  return { _x: rotation.x, _y: rotation.y, _z: rotation.z, _order: "XYZ" };
}

// [[0.1, 8, 10, 1.6, new THREE.Vector3(-1.2, -0.6, -12.7), 0]]
export class ForwardRibbon {
  constructor(name, data, config = DefaultForwardRibbonConfig()) {
    const ribbonGeometry = new THREE.PlaneGeometry(1.0, 1.0, 5, 128);
    this.time = 0;
    this.ribbon = new THREE.Mesh(
      ribbonGeometry,
      ribbonMaterial(
        config.length.value,
        config.width.value,
        config.position.value,
      ),
    );

    this.active = false;
    this.periodic = false;
    this.config = config;
    this.data = data;
    this.name = name;

    this.config.name = name;

    this.ribbon.scale.set(
      this.config.scale.value.x,
      this.config.scale.value.y,
      this.config.scale.value.z,
    );
    this.ribbon.rotation.copy(rotationTransform(this.config.rotation.value));
    this.ribbon.position.copy(this.config.position.value);
  }
  setData(data) {
    this.data = data;
    if (data) this.setConfigParameters();
  }

  addGUI(node) {
    const subnode = node.addFolder({ title: this.name });
    Object.keys(this.config).forEach((key) => {
      if (key === "name") return;
      const entry = this.config[key];
      if (entry.info != "texture" && entry.info != "model") {
        subnode
          .addInput(entry, "value", { ...entry.info, label: key })
          .on("change", () => {
            this.setConfigParameters();
          });
      } else
        subnode
          .addInput(entry, "value", {
            label: key,
            options: Object.keys(this.data.textures).reduce(
              (options, current) => {
                const o = {};
                o[current] = current;
                return { ...options, ...o };
              },
              {},
            ),
          })
          .on("change", () => {
            this.setConfigParameters();
          });
    });
  }

  setConfigParameters() {
    const mapName = this.config.map.value;
    this.ribbon.material.uniforms.map.value = this.data.textures[mapName];
    this.ribbon.material.uniforms.length.value = this.config.length.value;
    this.ribbon.material.uniforms.width.value = this.config.width.value;
    this.ribbon.material.uniforms.ribbonPosition.value.copy(
      this.config.position.value,
    );

    this.ribbon.scale.set(
      this.config.scale.value.x,
      this.config.scale.value.y,
      this.config.scale.value.z,
    );
    this.ribbon.rotation.copy(rotationTransform(this.config.rotation.value));
    this.ribbon.position.copy(this.config.position.value);
  }

  setMap(map) {
    this.ribbon.material.uniforms.map.value = map;
  }

  addToScene(object) {
    object.add(this.ribbon);
  }
  activate() {
    this.active = true;
    this.ribbon.visible = true;

    this.time = 0;
    this.ribbon.material.uniforms.time.value = this.time;
  }

  deactivate() {
    this.active = false;
    this.ribbon.visible = false;
  }
  togglePeriodicity() {
    this.periodic = !this.periodic;
  }
  getType() {
    return "ForwardRibbon";
  }
  update() {
    if (!this.active) return;
    let finished = false;
    if (this.time < 1.0 + 0.3) this.time += 0.007; //0.02;
    else finished = true;

    if (this.periodic) this.time = this.time % (1 + 0.3);
    this.ribbon.material.uniforms.time.value = this.time;
    if (finished && !this.periodic) this.deactivate();
  }
}

// const particleSystem = new DriftParticleSystem(1000, scene);
