import * as THREE from "three";
import vertex from "./point.glslv";
import fragment from "./point.glslf";

export const pointMaterial = () => {
  return new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    depthTest: true,
    depthWrite: false,
    alphaTest: 0.0,
    transparent: true,
    blending: THREE.NormalBlending,
    uniforms: {
      map: { value: null },
	    s: { value: 0 },
      u_size: { value: 1.0 },
    },
  });
};
