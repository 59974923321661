import * as THREE from "three";
import {
  createVehicle,
  createBoxObject,
  createModelObject,
  updateCellObject,
  updateMovingPhysicalObject,
  createModelCollectable,
	createComplexModelCollectable
} from "./objects";

import { assets } from "./loader";
import { getMaterial } from "./materials";
import { isStartScreen } from "./module";

const cellHalfSize = 100;
const littleGridResolution = 25; //15
const numberOfObjectsInCell = 60; //20;
const numberOfMovingObjects = 300;
//const numberOfCellsPerDimension = 500;

const expectedNumberOfCells = 50; //17; //20; //50; //20; //50;
let historyPointer = 0;
let cellPointer = 0;
let cells = [];
let cellsCoordMap = {};
let movingObjects = [];
let previousCoordString = "-1;-1";
let previousCoord = { x: -1, y: -1 };

const populationDictionary = {};

let initial = true;

const otherNames = [
  "Road_Aware_01",
  "Road_Aware_02",
  "Road_Blocker_01",
  "Road_Blocker_02",
  "Trash_Can_01",
  "Trash_Can_02",
  "Barrel_01",
  "Barrel_03",
  "Dump_01",
  "Dump_02",
  "Dump_03",
  "Dump_04",
];

const CollectableWeights = {
  Trash_01: 30,
  Trash_02: 25,
  Trash_03: 20,
  Trash_04: 10,
  Trash_05: 5,
  Trash_06: 2,
  Heal: 4,
  Nitro: 2,
  Armor: 2,
};

const Statistics = {
  Trash_01: 0,
  Trash_02: 0,
  Trash_03: 0,
  Trash_04: 0,
  Trash_05: 0,
  Trash_06: 0,
  Heal: 0,
  Nitro: 0,
  Armor: 0,
};

let predefined = null; //otherNames[11]; //7

const randomQuaternion = new THREE.Quaternion();
const yAxis = new THREE.Vector3(0.0, 1.0, 0.0);

function chooseRandomFromDict(dict) {
  const keys = Object.keys(dict);
  let index = Math.floor(keys.length * Math.random());
  return [dict[keys[index]], keys[index]];
}

function logStatistics() {
  const sum = Object.keys(Statistics).reduce((acc, key) => {
    return acc + Statistics[key];
  }, 0);

  Object.keys(Statistics).forEach((key) => {
    const percentage = Statistics[key] / sum;

    console.log(
      "Number of objects of type ",
      key,
      " is ",
      Statistics[key],
      " and percentage is ",
      percentage,
      ". ",
    );
  });
}

function chooseRandomFromDictWeighted(dict, weights) {
  const keys = Object.keys(dict);
  const random = 100.0 * Math.random();
  let index = -1;
  let accumulated = 0;
  for (let i = 0; i < keys.length; i++) {
    const value = accumulated;
    accumulated += weights[keys[i]];

    if (random < accumulated && random >= value) index = i;
  }
  if (index == -1) index = keys.length - 1;

  // let index = Math.floor(keys.length * Math.random());

  Statistics[keys[index]] += 1;

  return [dict[keys[index]], keys[index]];
}

function initializeGridStructures(scene, globalContext) {
  cells = [];
  //cellsCoordMap = {};
  movingObjects = [];
  previousCoordString = "-1;-1";
  previousCoord = { x: -1, y: -1 };
  initial = true;
  cellPointer = 0;
  historyPointer = 0;
  for (let i = 0; i < expectedNumberOfCells; i++) {
    const cellPrototype = {
      coord: null,
      initialized: false,
      index: -1,
      objects: [],
    };
    cells.push(cellPrototype);
  }
  historyPointer = 0;
  cellPointer = 0;
  globalContext.allocateMovingMemory(numberOfMovingObjects);
  globalContext.allocateCellMemory(
    numberOfObjectsInCell * expectedNumberOfCells,
  );

  Object.keys(cellsCoordMap).forEach((key) => {
    //deactivateCell(cellsCoordMap[key]);
    killCell(cellsCoordMap[key], scene);
  });

  populateCell({ x: 0, y: 0 }, { x: 0, y: 0 }, scene, globalContext);
}

function moveFromCellToMoving(object, globalContext) {
  const movingIndex = globalContext.moveFromCellToMoving(object.index);
  object.index = movingIndex;

  movingObjects.push(object);
  object.moving = true;
}

function moveFromMovingToCell(object, globalContext) {
  const cellCoord = getCurrentCellCoordinate(object.object.position);
  const cellCoordString = "" + cellCoord.x + ";" + cellCoord.y;
  //console.log(cellCoordString);

  const cell = cellsCoordMap[cellCoordString];
  if (!cell) return;

  const cellIndex = globalContext.moveFromMovingToCell(
    object.index,
    cell.index,
  );
  object.index = cellIndex;
  object.moving = false;

  if (object.cellCoordString !== cellCoordString) {
    cell.objects.push(object);
    object.cellCoordString = cellCoordString;
  }

  if (cellCoordString !== previousCoordString)
    globalContext.deactivateBody(object.index);
}

function killCell(cell, scene) {
  const coord = cell.coord;
  const coordString = "" + coord.x + ";" + coord.y;

  cell.objects.forEach((o) => {
    if (!o.isGround) scene.remove(o.object);
  });

  cell.coord = null;
  cell.initialized = false;
  cell.index = -1;
  cell.objects = [];
  delete cellsCoordMap[coordString];
}

function chooseRandomNonPopulatedPlace(cellSize, coordString) {
  let chosen = false;
  let N = -1;
  while (!chosen) {
    N = Math.floor(cellSize * Math.random());
    if (
      !populationDictionary[N] ||
      !(populationDictionary[N] === coordString)
    ) {
      chosen = true;
      populationDictionary[N] = coordString;
    }
  }
  return N;
}

function checkIfCellInVicinity(cell, coord) {
  const vicinity = [
    { x: coord.x, y: coord.y },
    { x: coord.x + 2 * cellHalfSize, y: coord.y },
    { x: coord.x - 2 * cellHalfSize, y: coord.y },
    {
      x: coord.x + 2 * cellHalfSize,
      y: coord.y + 2 * cellHalfSize,
    },
    { x: coord.x, y: coord.y + 2 * cellHalfSize },
    {
      x: coord.x - 2 * cellHalfSize,
      y: coord.y + 2 * cellHalfSize,
    },
    {
      x: coord.x + 2 * cellHalfSize,
      y: coord.y - 2 * cellHalfSize,
    },
    { x: coord.x, y: coord.y - 2 * cellHalfSize },
    {
      x: coord.x - 2 * cellHalfSize,
      y: coord.y - 2 * cellHalfSize,
    },
  ];
  let result = false;
  vicinity.forEach((c) => {
    result |= c.x === cell.coord.x && c.y === cell.coord.y;
  });
  return result > 0 ? true : false;
}

function populateCell(coord, currentCoord, scene, globalContext) {
  // console.log("Populating cell: ", cellPointer, coord);
  const coordString = "" + coord.x + ";" + coord.y;

  if (!!cellsCoordMap[coordString]) {
    // console.log("Populated, no need to do anything");
    return;
  }

  // const cell = cells[cellPointer];

  //check for vicinity solves the bug with the car falling. Safe solution seems to be not allowing to kill
  // cells, which are in vicinity of a current cell with a car. That seemed to be unreallistic in the beginning,
  // but actually it is reallistic. You could make circles around a cell, it would be somewhere in the beginning
  // of a cell array, and then when you come in the current cell, cell from vicinity would ask for a place, that is taken by the current cell:.
  // In that case the cell is killed
  let cell = cells[cellPointer];
  let isInVicinity =
    cell.initialized && checkIfCellInVicinity(cell, currentCoord);
  // if (isInVicinity) console.log("WORKED AT LEAST");

  while (isInVicinity) {
    cellPointer++;
    cellPointer = cellPointer % expectedNumberOfCells;
    cell = cells[cellPointer];
    isInVicinity =
      cell.initialized && checkIfCellInVicinity(cell, currentCoord);
  }

  if (cell.initialized) {
    // console.log("Populated, but need space, so kill it");
    killCell(cell, scene);
  }

  // console.log("constructing new");
  // console.log(cellPointer);
  cell.coord = coord;
  cell.initialized = true;
  cell.index = cellPointer;
  cellsCoordMap[coordString] = cell;

  globalContext.setPointerToCellStart(cellPointer, numberOfObjectsInCell);

  createBoxObject(
    new THREE.Vector3(cellHalfSize, 0.5, cellHalfSize),
    new THREE.Vector3(coord.x, -0.5, coord.y),
    0.0,
    new THREE.Quaternion(),
    getMaterial("Ground"),
    cell.objects,
    coordString,
  );

  // box
  for (let i = 0; i < numberOfObjectsInCell; i++) {
    // const N = Math.floor(
    //   littleGridResolution * littleGridResolution * Math.random(),
    // );
    const N = chooseRandomNonPopulatedPlace(
      littleGridResolution * littleGridResolution,
      coordString,
    );
    const littleCellSize = (2 * cellHalfSize) / littleGridResolution;
    const xIndex = Math.floor(N / littleGridResolution);
    const yIndex = N % littleGridResolution;

    const x =
      xIndex * littleCellSize + 0.5 * littleCellSize + coord.x - cellHalfSize;
    const y =
      yIndex * littleCellSize + 0.5 * littleCellSize + coord.y - cellHalfSize;

    const inVicinityOfZero = Math.abs(x) < 1.0 && Math.abs(y) < 1.0;

    const [matcap, _] = chooseRandomFromDict(assets.matcaps);
    const type = Math.random();
    randomQuaternion.setFromAxisAngle(yAxis, 2 * Math.PI * Math.random());

    if (type < 0.5 && !isStartScreen() && !inVicinityOfZero) {
      let model, name;
      if (!predefined) [model, name] = chooseRandomFromDict(assets.objects);
      else {
        model = assets.objects[predefined];
        name = predefined;
      }
      const size = model.boundingBox.getSize(new THREE.Vector3());

      createModelObject(
        model,
        new THREE.Vector3(x, -0.2 + size.y / 2.0, y),
        140.0,
        randomQuaternion,
        getMaterial(name),
        cell.objects,
        coordString,
        name,
      );
    } else {
      const [model, name] = chooseRandomFromDictWeighted(
        assets.collectables,
        CollectableWeights,
      );

      if (name == "Heal" || name == "Nitro" || name == "Armor") {
        createComplexModelCollectable(
          model,
          new THREE.Vector3(x, 0.5, y),
          10.0,
          randomQuaternion,
          getMaterial(name),
          cell.objects,
          coordString,
          name,
        );
      } else {
        createModelCollectable(
          model,
          new THREE.Vector3(x, 0.5, y),
          10.0,
          randomQuaternion,
          getMaterial(name),
          cell.objects,
          coordString,
          name,
        );
      }
    }
  }

  cell.objects.forEach((o) => {
    if (!o.isGround) scene.add(o.object);
  });

  cellPointer++;
  cellPointer = cellPointer % expectedNumberOfCells;

  //globalContext.resetPool();
}

function populateVicinity(coord, scene, globalContext) {
  // console.log("Populating vicinity of ", coord);
  populateCell(
    { x: coord.x + 2 * cellHalfSize, y: coord.y },
    coord,
    scene,
    globalContext,
  );
  populateCell(
    { x: coord.x - 2 * cellHalfSize, y: coord.y },
    coord,
    scene,
    globalContext,
  );
  populateCell(
    {
      x: coord.x + 2 * cellHalfSize,
      y: coord.y + 2 * cellHalfSize,
    },
    coord,
    scene,
    globalContext,
  );
  populateCell(
    { x: coord.x, y: coord.y + 2 * cellHalfSize },
    coord,
    scene,
    globalContext,
  );
  populateCell(
    {
      x: coord.x - 2 * cellHalfSize,
      y: coord.y + 2 * cellHalfSize,
    },
    coord,
    scene,
    globalContext,
  );
  populateCell(
    {
      x: coord.x + 2 * cellHalfSize,
      y: coord.y - 2 * cellHalfSize,
    },
    coord,
    scene,
    globalContext,
  );
  populateCell(
    { x: coord.x, y: coord.y - 2 * cellHalfSize },
    coord,
    scene,
    globalContext,
  );
  populateCell(
    {
      x: coord.x - 2 * cellHalfSize,
      y: coord.y - 2 * cellHalfSize,
    },
    coord,
    scene,
    globalContext,
  );
}

function getCurrentCellCoordinate(position) {
  let x = Math.floor((position.x + cellHalfSize) / (2 * cellHalfSize));
  let y = Math.floor((position.z + cellHalfSize) / (2 * cellHalfSize));

  x *= 2 * cellHalfSize;
  y *= 2 * cellHalfSize;

  return { x, y };
}

function checkIfInHistoy(coord) {
  for (let i = 0; i < history.length; i++) {
    if (history.coord.x == coord.x && history.coord.y == coord.y) return true;
  }
  return false;
}

function deactivateCell(cellCoord, globalContext) {
  let cellCoordString;
  if (typeof cellCoord == "string") cellCoordString = cellCoord;
  else cellCoordString = "" + cellCoord.x + ";" + cellCoord.y;
  const cell = cellsCoordMap[cellCoordString];
  if (!cell) return;
  cell.objects.forEach((object, j) => {
    if (j === 0) return;
    if (
      object.type === "physical" &&
      !object.moving &&
      object.cellCoordString === cellCoord
    ) {
      const index = object.index;
      globalContext.deactivateBody(index);
    }
  });
}
function activateCell(cellCoord, globalContext) {
  let cellCoordString;
  if (typeof cellCoord == "string") cellCoordString = cellCoord;
  else cellCoordString = "" + cellCoord.x + ";" + cellCoord.y;

  const cell = cellsCoordMap[cellCoordString];
  if (!cell) return;
  cell.objects.forEach((object) => {
    //if (j === 0) return;

    if (
      object.type === "physical" &&
      !object.moving &&
      object.cellCoordString === cellCoord
    ) {
      const index = object.index;
      globalContext.activateBody(index);
    }
  });
}
function deactivateVicinity(coord, globalContext) {
  deactivateCell({ x: coord.x, y: coord.y }, globalContext);
  deactivateCell({ x: coord.x + 2 * cellHalfSize, y: coord.y }, globalContext);
  deactivateCell({ x: coord.x - 2 * cellHalfSize, y: coord.y }, globalContext);
  deactivateCell(
    {
      x: coord.x + 2 * cellHalfSize,
      y: coord.y + 2 * cellHalfSize,
    },
    globalContext,
  );
  deactivateCell({ x: coord.x, y: coord.y + 2 * cellHalfSize }, globalContext);
  deactivateCell(
    {
      x: coord.x - 2 * cellHalfSize,
      y: coord.y + 2 * cellHalfSize,
    },
    globalContext,
  );
  deactivateCell(
    {
      x: coord.x + 2 * cellHalfSize,
      y: coord.y - 2 * cellHalfSize,
    },
    globalContext,
  );
  deactivateCell({ x: coord.x, y: coord.y - 2 * cellHalfSize }, globalContext);
  deactivateCell(
    {
      x: coord.x - 2 * cellHalfSize,
      y: coord.y - 2 * cellHalfSize,
    },
    globalContext,
  );
}

function activateVicinity(coord, globalContext) {
  activateCell({ x: coord.x, y: coord.y }, globalContext);
  activateCell({ x: coord.x + 2 * cellHalfSize, y: coord.y }, globalContext);
  activateCell({ x: coord.x - 2 * cellHalfSize, y: coord.y }, globalContext);
  activateCell(
    {
      x: coord.x + 2 * cellHalfSize,
      y: coord.y + 2 * cellHalfSize,
    },
    globalContext,
  );
  activateCell({ x: coord.x, y: coord.y + 2 * cellHalfSize }, globalContext);
  activateCell(
    {
      x: coord.x - 2 * cellHalfSize,
      y: coord.y + 2 * cellHalfSize,
    },
    globalContext,
  );
  activateCell(
    {
      x: coord.x + 2 * cellHalfSize,
      y: coord.y - 2 * cellHalfSize,
    },
    globalContext,
  );
  activateCell({ x: coord.x, y: coord.y - 2 * cellHalfSize }, globalContext);
  activateCell(
    {
      x: coord.x - 2 * cellHalfSize,
      y: coord.y - 2 * cellHalfSize,
    },
    globalContext,
  );
}

function updateGrid(vehicle, scene, globalContext) {
  // logStatistics();
  const coord = getCurrentCellCoordinate(vehicle.position);
  populateVicinity(coord, scene, globalContext);

  const coordString = "" + coord.x + ";" + coord.y;

  if (coordString !== previousCoordString && !initial) {
    deactivateCell(previousCoordString, globalContext);
    activateCell(coordString, globalContext);
    //deactivateVicinity(previousCoord, globalContext);
    //activateVicinity(coord, globalContext);
  }

  if (initial) {
    activateCell(coordString, globalContext);
    //activateVicinity(coord, globalContext);
  }

  initial = false;
  previousCoord = coord;
  previousCoordString = coordString;
  const cell = cellsCoordMap[coordString];
  if (!cell) {
    //console.log(cellsCoordMap, coordString, previousCoordString);
    return;
  }
  cell.objects.forEach((o) => {
    //objects array of a cell might contain objects that were moved, but those objects should be handled elsewhere
    if (o.cellCoordString === coordString && !o.moving)
      updateCellObject(o, vehicle);
  });

  if (movingObjects.length > 0) {
    let o = movingObjects[0];
    while (o && !o.moving) {
      movingObjects.shift();
      if (movingObjects.length > 0) o = movingObjects[0];
      else o = null;
    }
  }

  movingObjects.forEach((o) => {
    //moving objects array might contain settled objects that don't move anymore, but those should be handled elsewhere
    if (o.moving) {
      updateMovingPhysicalObject(o);
    }
  });

  //for (let i = 0; i < cellPointer; i++) {
  //const cell = cells[i];
  //cell.objects.forEach((o) => updateObject(o));
  //}
}

export {
  updateGrid,
  initializeGridStructures,
  moveFromCellToMoving,
  moveFromMovingToCell,
  activateCell,
  populateVicinity,
  activateVicinity,
  getCurrentCellCoordinate,
};
